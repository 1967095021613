import React, { Suspense, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { routes } from "../../constants";
import CommonHeading from "../../component/Common/CommonHeading";
import Pagination from "../../component/Pagination/Pagination";
// import UserListTable from "../../component/Common/PageComponent/UserComponent/UserListTable";
import { useGetUserListQuery } from "../../store/Users/usersSlice";
import Loader from "../../component/Common/Loader";
import { userPageFilter } from "../../component/Common/PageComponent/UserComponent/UserPageComponents";
import { clearFilter } from "../../store/filters/filterStateSlice";
import { toastifyMsg } from "../../helpers/commonFunction";

const UserItem = React.lazy(() =>
  import("../../component/Common/PageComponent/UserComponent/UserItem")
);

const UserManagement = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page_number } = params;

  const filters = useSelector((state) => state.filterStateSlice.items);
  console.log("filters2231", filters.userSearch);

  const [pageNumber, setPageNumber] = useState(page_number);
  const [searchData, setSearchData] = useState(filters.userSearch);
  console.log("pageNumber", pageNumber);

  const {
    data: response,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetUserListQuery({
    start: +pageNumber !== 1 && (pageNumber - 1) * 12,
    limit: 12,
    search_key: searchData,
  });

  const totalCount = response?.totalCount;

  let userList;
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    userList = response?.apiResponse;
  }

  const onPageChange = () => {
    setPageNumber(page_number);
  };

  const onChangingSearchField = (e) => {
    setSearchData(e.target.value);
    if (page_number !== "1") {
      navigate(`/user/page/1`);
      console.log("NAVIGATE---TO---PAGE1");
    }
    console.log("e.target.value", e.target.value);
  };

  console.log("searchData", searchData);

  const addUserPageHandler = () => {
    navigate(routes.createUser);
    dispatch(clearFilter());
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="USER MANAGEMENT" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <button
                  className="comman_btn ml-auto"
                  onClick={(e) => addUserPageHandler()}
                >
                  Add User
                </button>
              </div>
              {userPageFilter({ onChangingSearchField, searchData })}
            </div>

            {/* <div className="custom_data_table_content">
              <UserListTable searchData={searchData} list={userList} />
            </div> */}
            <Suspense fallback={<Loader />}>
              <UserItem userList={userList} searchData={searchData} />
            </Suspense>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
                pageLimit={12}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UserManagement;
