import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const messageSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMessageRecord: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-message-record?salon_owner_id=${
            data.salon_owner_id
          }&salon_id=${data.salon_id}&start=${data.start || 0}&limit=${
            data.limit || 10
          }`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["MessageRecord"],
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),
    createMessageRecord: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/create-message-record",
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["MessageRecord", "History"],
    }),
    updateMessageRecord: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/auth/update-message-record/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["MessageRecord", "History"],
    }),
  }),
});

export const {
  useGetMessageRecordQuery,
  useCreateMessageRecordMutation,
  useUpdateMessageRecordMutation,
} = messageSlice;
