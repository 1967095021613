import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../../css/component.css";
import Logo from "../../images/smartBeautyLogo/sidebarLogo.png";
import sidebarSmallIcon from "../../images/smartBeautyLogo/onlylogo.png";
import { routes } from "../../constants";
import {
  serviceIcon,
  categoryImg,
  coupon,
  dashboardIcon,
  paymentHistory,
  salonManagementIcon,
  bookingIcon,
  settingsIcon,
} from "../../icons";
import { clearFilter } from "../../store/filters/filterStateSlice";
import { useDispatch } from "react-redux";

const Sidebar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = JSON.parse(localStorage?.getItem("user")).user_role;

  console.log("userRole", userRole);

  const sidebarNavigateHandler = (navigateTo) => {
    dispatch(clearFilter());
    if (navigateTo === "dashboard") {
      navigate(routes.dashboard);
    }
    if (navigateTo === "user") {
      navigate(`/user/page/1`);
    }
    if (navigateTo === "coupon") {
      navigate(`${routes.couponMangement}/page/1`);
    }
    if (navigateTo === "services") {
      navigate(routes.service);
    }
    if (navigateTo === "category") {
      navigate(routes.serviceCategory);
    }
    if (navigateTo === "payment") {
      navigate(`payment-history/page/1`);
    }
    if (navigateTo === "settings") {
      navigate(`settings`);
    }
  };

  const adminNavigateHandler = (navigateTo) => {
    if (navigateTo === "dashboard") {
      navigate(routes.dashboard);
    }
    if (navigateTo === "adminUser") {
      navigate(`user/salon/page/1`);
    }
  };

  return (
    <div className="sidebar_container">
      <button
        onClick={(e) => sidebarNavigateHandler("dashboard")}
        className={`sidebar_logo ${
          pathname.match(/^.*dashboard.*$/) && "active"
        } `}
      >
        <div>
          <img
            src={Logo}
            alt="smartBeauty_centre-logo"
            className="full_sidebar_logo"
          />
          <img
            src={sidebarSmallIcon}
            alt="smartBeauty_centre-logo"
            className="small_sidebar_logo"
          />
        </div>
      </button>
      {userRole === "SUPER_ADMIN" ? (
        <ul className="sidebar_nav">
          <li className="sidebar_item">
            <button
              role={"button"}
              className={`sidebar_link ${
                pathname.match(/^.*dashboard.*$/) && "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("dashboard")}
            >
              {dashboardIcon}
              <span>Dashboard</span>
            </button>
          </li>
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                (pathname.match(/^.*user.*$/) ||
                  pathname.match(/^.*salon.*$/)) &&
                "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("user")}
            >
              {salonManagementIcon}
              <span>User Management</span>
            </button>
          </li>

          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                (pathname.match(/^.*coupon.*$/) ||
                  pathname.match(/^.*coupon.*$/)) &&
                "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("coupon")}
            >
              {coupon}
              <span>Coupon Management</span>
            </button>
          </li>
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*service-icons.*$/) && "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("services")}
            >
              {serviceIcon}
              <span>Service Icons</span>
            </button>
          </li>
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*service-category.*$/) && "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("category")}
            >
              {categoryImg}
              <span>Category Images</span>
            </button>
          </li>
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*payment-history.*$/) && "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("payment")}
            >
              {paymentHistory}
              <span>Payment History</span>
            </button>
          </li>
          {/* <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*settings.*$/) && "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("settings")}
            >
              {settingsIcon}
              <span>Settings</span>
            </button>
          </li> */}
        </ul>
      ) : (
        <ul className="sidebar_nav">
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*dashboard.*$/) && "active"
              } `}
              onClick={(e) => adminNavigateHandler("dashboard")}
            >
              {dashboardIcon}
              <span>Dashboard</span>
            </button>
          </li>
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*user.*$/) && "active"
              } `}
              onClick={(e) => adminNavigateHandler("adminUser")}
            >
              {salonManagementIcon}
              <span>User Details</span>
            </button>
          </li>
          <li className="sidebar_item">
            <button
              className={`sidebar_link ${
                pathname.match(/^.*settings.*$/) && "active"
              } `}
              onClick={(e) => sidebarNavigateHandler("settings")}
            >
              {settingsIcon}
              <span>Settings</span>
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
