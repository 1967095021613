export const routes = {
  // SUPER ADMIN ROUTES
  homepage: "/",
  all: "*",
  login: "/login",

  dashboard: "/dashboard",

  userManagementByPage: "/user/page/:page_number",
  createUser: "/create-user",
  editUser: "/edit-user/:id",

  createSalon: "/user/:id/create-salon",
  editSalon: "/user/:id/salon/:salon_id",
  salonManagementByPage: "/user/:id/salon/page/:page_number",
  viewSalon: "/user/:id/view-salon/:salon_id",

  couponMangement: "/coupon-management",
  couponMangementByPage: "/coupon-management/page/:page_number",
  createCoupon: "/create-coupon",
  updateCoupon: "/update-coupon/:id",
  viewCoupon: "/view-coupon/:id",

  service: "/service-icons",
  serviceCategory: "/service-category",

  licenceByPage: "/user/:id/salon-licence/:salon_id/page/:page_number",

  messageByPage: "/user/:id/salon-message-record/:salon_id/page/:page_number",

  bookings: "/user/:id/salon-booking/:salon_id/page/:page_number",

  paymentHistory: "/payment-history/page/:page_number",

  contact: "/contacts",

  settings: "/settings",

  settingForm: "/settings-form",

  // ADMIN ROUTES

  viewUserForAdmin: "user/salon/page/:page_number",
  viewSalonForAdmin: "user/view-salon",
  viewBookingForAdmin: "user/salon-bookings/page/:page_number",
};
