import React from "react";
import Button from "../../Button";
import Input from "../../Input";
import PhoneInput from "react-phone-number-input";

const UserPageForm = ({
  userId,
  values,
  handleInputChange,
  errors,
  createUpdateUserHandler,
  setValues,
  setErrors,
}) => {
  return (
    <>
      <div className="create_from_row">
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter User Name"
          label="User Name"
          id="name"
          name="name"
          value={values.name}
          onChange={handleInputChange}
          error={errors?.name || ""}
        />
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="email"
          placeholder="Enter User Email Address"
          label="Email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors?.email || ""}
        />

        <div className="project_edit_feature_input">
          <label>Phone Number</label>
          <PhoneInput
            placeholder="Phone number"
            value={values.phone_number}
            defaultCountry="DE"
            onChange={(e) => {
              setValues({
                ...values,
                phone_number: e,
                phone_number_error: "",
              });
              setErrors({
                ...errors,
                phone_number: "",
              });
            }}
          />
          {errors.phone_number && (
            <span className="err_text">{errors.phone_number}</span>
          )}
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={createUpdateUserHandler}
        text={userId ? "Update" : "Add"}
      />
    </>
  );
};

export default UserPageForm;
