import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/Login/LoginPage";
import Page404 from "./pages/Page404/Page404";

import { routes } from "../src/constants";
import SalonManagement from "./pages/salon/SalonManagement";
import CreateSalon from "./pages/salon/CreateSalon";
import EditSalon from "./pages/salon/EditSalon";
import ViewSalon from "./pages/salon/ViewSalon";
import ServiceIcons from "./pages/ServiceIcons/ServiceIcons";
import ServiceCategory from "./pages/ServiceCategory/ServiceCategory";
import Licence from "./pages/Licence/Licence";
import BookingPage from "./pages/Booking/BookingPage";
import MessagePage from "./pages/Messages/MessagePage";
import CouponManagement from "./pages/Coupon/CouponManagement";
import CreateCoupon from "./pages/Coupon/CreateCoupon";
import UpdateCoupon from "./pages/Coupon/UpdateCoupon";
import ViewCoupon from "./pages/Coupon/ViewCoupon";
import PaymentHistory from "./pages/History/PaymentHistory";
import ViewUserForAdmin from "./pages/Users/ViewUserForAdmin";
import BookingForAdmin from "./pages/Booking/bookingForAdmin";
import UserManagement from "./pages/Users/UserManagement";
import CreateUser from "./pages/Users/CreateUser";
import EditUser from "./pages/Users/EditUser";
import ViewSalonForAdmin from "./pages/salon/ViewSalonForAdmin";
import SettingsPage from "./pages/Settings/SettingsPage";
import CronSettingPage from "./pages/Settings/CronSettingPage";

const getRoutes = (user) => {
  console.log("USERUSER", user?.user_role);
  return [
    {
      path: routes.homepage,
      element: !user ? <AuthPage /> : <Navigate to={routes.dashboard} />,
      children: [
        { path: routes.login, element: <LoginPage /> },
        { path: routes.homepage, element: <Navigate to={routes.login} /> },
      ],
    },
    {
      path: routes.homepage,
      element: user ? <Layout /> : <Navigate to={routes.login} />,
      children:
        user && user?.user_role === "SUPER_ADMIN"
          ? [
              { path: routes.dashboard, element: <Dashboard /> },

              {
                path: routes.userManagementByPage,
                element: <UserManagement />,
              },
              { path: routes.createUser, element: <CreateUser /> },
              { path: routes.editUser, element: <EditUser /> },

              {
                path: routes.salonManagementByPage,
                element: <SalonManagement />,
              },
              { path: routes.createSalon, element: <CreateSalon /> },
              { path: routes.editSalon, element: <EditSalon /> },
              { path: routes.viewSalon, element: <ViewSalon /> },

              { path: routes.couponMangement, element: <CouponManagement /> },
              {
                path: routes.couponMangementByPage,
                element: <CouponManagement />,
              },

              { path: routes.createCoupon, element: <CreateCoupon /> },
              { path: routes.updateCoupon, element: <UpdateCoupon /> },
              { path: routes.viewCoupon, element: <ViewCoupon /> },

              { path: routes.bookings, element: <BookingPage /> },
              { path: routes.licenceByPage, element: <Licence /> },
              { path: routes.messageByPage, element: <MessagePage /> },

              { path: routes.service, element: <ServiceIcons /> },
              { path: routes.serviceCategory, element: <ServiceCategory /> },

              { path: routes.paymentHistory, element: <PaymentHistory /> },

              { path: routes.settings, element: <SettingsPage /> },
              { path: routes.settingForm, element: <CronSettingPage /> },

              {
                path: routes.homepage,
                element: <Navigate to={routes.dashboard} />,
              },
            ]
          : [
              { path: routes.dashboard, element: <Dashboard /> },
              {
                path: routes.viewUserForAdmin,
                element: <ViewUserForAdmin />,
              },
              {
                path: routes.viewSalonForAdmin,
                element: <ViewSalonForAdmin />,
              },
              {
                path: routes.viewBookingForAdmin,
                element: <BookingForAdmin />,
              },

              { path: routes.settings, element: <SettingsPage /> },
              { path: routes.settingForm, element: <CronSettingPage /> },
            ],
    },
    {
      path: "",
      element: !user ? <Navigate to={routes.login} /> : <Page404 />,
      children: [
        { path: routes.login, element: <LoginPage /> },
        { path: "*", element: <LoginPage /> },
      ],
    },
  ];
};

export default getRoutes;
