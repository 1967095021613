import React, { useEffect, useState } from "react";
import { toastifyMsg } from "../../../helpers/commonFunction";
import { useForm } from "../../../hooks/useForm";
import { eyeIcon, hideEyeIcon } from "../../../icons";
import {
  useChangePasswordFromUserMutation,
  useResetPassFromSuperAdminMutation,
} from "../../../store/auth/authSlice";
import Input from "../Input";
import { resetPasswordValidation } from "../PageComponent/UserComponent/UserPageComponents";

const ResetPasswordModal = ({
  hideModal,
  admin,
  setChangePasswordModal,
  user_id,
}) => {
  console.log("user_id", user_id);
  const [resetPassword, { data, isLoading, error, isError, isSuccess }] =
    useResetPassFromSuperAdminMutation();

  const [
    changePassword,
    {
      data: data1,
      isLoading: changing,
      error: changeError,
      isError: changeIsError,
      isSuccess: changeIsSuccess,
    },
  ] = useChangePasswordFromUserMutation();

  const initialFValues = {
    old_password: "",
    password: "",
    confirm_password: "",
    oldInputType: "password",
    inputType: "password",
    confirmInputType: "password",
  };

  // changePassword from User
  useEffect(() => {
    if (changeIsError) {
      toastifyMsg("error", changeError?.data?.message);
      console.log("error", changeError?.data?.message);
      setValues({
        ...values,
        old_password: "",
        password: "",
        confirm_password: "",
        oldInputType: "password",
        inputType: "password",
        confirmInputType: "password",
      });
    } else if (changeIsSuccess) {
      console.log("data1", data1);
      toastifyMsg("success", data1?.message);
      setChangePasswordModal(false);
    }
  }, [changeIsError, changeError, changeIsSuccess]);
  //

  // changePassword from Admin
  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("error", error?.data?.message);
      setValues({
        ...values,
        password: "",
        confirm_password: "",
        inputType: "password",
        confirmInputType: "password",
      });
    } else if (isSuccess) {
      console.log("data1", data);
      toastifyMsg("success", data?.message);
      setChangePasswordModal(false);
    }
  }, [isError, error, isSuccess]);
  //

  const eyeIconHandler = (pType) => {
    if (pType === "old_password") {
      setValues({
        ...values,
        oldInputType: values.oldInputType === "password" ? "text" : "password",
      });
    } else if (pType === "password") {
      setValues({
        ...values,
        inputType: values.inputType === "password" ? "text" : "password",
      });
    } else {
      setValues({
        ...values,
        confirmInputType:
          values.confirmInputType === "password" ? "text" : "password",
      });
    }
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    return resetPasswordValidation(temp, fieldValues, values, setErrors, admin);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const passwordValidate = () => {
    if (values.password !== values.confirm_password) {
      setErrors({
        ...errors,
        confirm_password: "The password confirmation does not match!",
      });
    } else {
      return true;
    }
  };

  const resetPasswordHandler = () => {
    if (validate() && passwordValidate()) {
      let data;
      if (admin) {
        data = {
          old_password: values.old_password,
          new_password: values.password,
        };
        changePassword(data);
        console.log("ADMIN ===> ADMIN", data);
      } else {
        data = {
          password: values.password,
        };
        resetPassword({ data, id: user_id });
        console.log("ADMIN ===> SUPER_ADMIN", data);
      }
    } else {
      console.log("data not Validate");
    }
  };

  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h2>{admin ? "Change Password" : "Reset Password"}</h2>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideModal()}>X</button>
        </div>
      </div>
      <div>
        <div className="modal_input_container">
          {admin && (
            <Input
              className={`modal_input_field`}
              errorClassName="err_text"
              type={values.oldInputType}
              placeholder="Enter Old Password"
              label="Old Password"
              id="old_password"
              name="old_password"
              eyeIcon={
                values.oldInputType === "password" ? eyeIcon : hideEyeIcon
              }
              eyeIconHandler={() => eyeIconHandler("old_password")}
              PasswordClassName={true}
              value={values.old_password}
              onChange={handleInputChange}
              error={errors?.old_password || ""}
            />
          )}
          <Input
            className={`modal_input_field`}
            errorClassName="err_text"
            type={values.inputType}
            placeholder="Enter Password"
            label="New Password"
            id="password"
            name="password"
            eyeIcon={values.inputType === "password" ? eyeIcon : hideEyeIcon}
            eyeIconHandler={() => eyeIconHandler("password")}
            PasswordClassName={true}
            value={values.password}
            onChange={handleInputChange}
            error={errors?.password || ""}
          />
        </div>
        <div className="modal_input_container">
          <Input
            className={`modal_input_field`}
            errorClassName="err_text"
            type={values.confirmInputType}
            placeholder="Confirm Password"
            label="Confirm Password"
            id="confirm_password"
            name="confirm_password"
            value={values.confirm_password}
            onChange={handleInputChange}
            error={errors?.confirm_password || ""}
            eyeIcon={
              values.confirmInputType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={() => eyeIconHandler()}
            PasswordClassName={true}
          />
        </div>
      </div>
      <div className="modal_btn_row modal_btn_row_top_margin">
        <button
          className="modal_btn modal_cancel_btn msg_btn"
          onClick={() => hideModal()}
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_create_btn msg_btn"
          onClick={() => resetPasswordHandler()}
          disabled={changing || isLoading}
        >
          {admin
            ? changing
              ? "Loading..."
              : "Change"
            : isLoading
            ? "Loading..."
            : "Reset"}
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
