import React from "react";

const UserDetail = ({ userD }) => {
  console.log("userDuserDuserD", userD);
  return (
    <div className="full_detail_container">
      <div>
        <div className="project_view_list_item">User Name</div>
        <p>{userD?.name}</p>
      </div>
      <div>
        <div className="project_view_list_item">User Email</div>
        <p className="list_item_email">{userD?.email}</p>
      </div>
      <div>
        <div className="project_view_list_item">User Phone Number</div>
        <p>{"+" + userD?.country_code + " " + userD?.phone_number}</p>
      </div>
    </div>
  );
};

export default UserDetail;
