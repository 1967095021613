import React from "react";
import { useNavigate } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { toastifyMsg } from "../../helpers/commonFunction";
import { useGetCronSettingQuery } from "../../store/setting/settingSlice";
import CronDetail from "../../component/Common/PageComponent/SettingsComponent/CronDetail";

const SettingsPage = () => {
  const navigate = useNavigate();

  const {
    data,
    isLoading: cronLoading,
    isError: cronIsError,
    error: cronError,
    isSuccess: cronIsSuccess,
  } = useGetCronSettingQuery();

  let cronSetting;
  if (cronIsError) {
    toastifyMsg("error", cronError?.data?.message);
  } else if (cronIsSuccess) {
    console.log("CALLED", data);
    cronSetting = data;
  }
  console.log("cronSetting", cronSetting);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"SETTINGS"} />
      {cronLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link setting_page">
            <div className="comman_center_btn_container">
              <button
                className="comman_btn"
                onClick={() => navigate(`/settings-form`)}
              >
                {cronSetting ? "Change" : "Create"}
              </button>
            </div>
          </div>
          {cronSetting ? (
            <CronDetail cronSetting={cronSetting} />
          ) : (
            <div className="create_from_row create_from_row_no_data_msg">
              You have to first create Cron Timing!
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SettingsPage;
