import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-users?start=${data.start || 0}&limit=${
            data.limit || 1000000
          }&search_key=${data.search_key || ""}`,
          method: "GET",
          headers: authHeaders(),
        };
      },

      providesTags: ["User"],
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),
    getUserById: builder.query({
      query: ({ id }) => {
        return {
          url: `/smart-beauty/auth/get-user-by-id?user_id=${id}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/create-user",
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/auth/update-user/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserListQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
} = usersSlice;
