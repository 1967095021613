import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const paymentHistorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getpaymentList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-payment-history?user_id=&start=${
            data.start || 0
          }&limit=${data.limit || 10}&payment_for=${data.payment_for || ""}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["History"],
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),
  }),
});

export const { useGetpaymentListQuery } = paymentHistorySlice;
