import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}` }),

  tagTypes: [
    "User",
    "Salon",
    "Coupon",
    "Licence",
    "Services",
    "ServiceCategory",
    "MessageRecord",
    "History",
    "Setting",
  ],
  endpoints: (builder) => ({}),
});
