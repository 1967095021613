import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import MsgRecordModal from "../../component/Common/Modals/MsgRecordModal";
import { useForm } from "../../hooks/useForm";
import { backBtnArrow } from "../../icons";
import {
  useCreateMessageRecordMutation,
  useGetMessageRecordQuery,
  useUpdateMessageRecordMutation,
} from "../../store/message/messageSlice";
import Pagination from "../../component/Pagination/Pagination";
import { toastifyMsg } from "../../helpers/commonFunction";
// import MsgRecordList from "../../component/Common/PageComponent/MsgRecordComponent/MsgRecordList";

const MsgRecordList = React.lazy(() =>
  import(
    "../../component/Common/PageComponent/MsgRecordComponent/MsgRecordList"
  )
);

const MessagePage = () => {
  const params = useParams();
  console.log("paramsIDIDIDID", params);
  const page_number = params?.page_number;
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(page_number);

  const { data: messageRecordData, isLoading: messageLoading } =
    useGetMessageRecordQuery({
      salon_owner_id: params.id,
      salon_id: params.salon_id,
      start: +pageNumber !== 1 && (pageNumber - 1) * 10,
      limit: 10,
    });
  console.log("messageRecord", messageRecordData);

  const totalCount = messageRecordData?.totalCount;
  console.log("totalCount98765", totalCount);

  const [createMsgRecord, { data, isLoading, error, isError, isSuccess }] =
    useCreateMessageRecordMutation();

  const [
    updateMsgRecord,
    {
      data: updatedData,
      isLoading: Updating,
      error: updateMsgError,
      isError: updateMsgIsError,
      isSuccess: Updated,
    },
  ] = useUpdateMessageRecordMutation();

  const initialFValues = {
    msgModal: false,
    message_amount: "",
    message_credit: "",
    messageId: "",
    previousAmount: "",
    previousCredit: "",
    payment_status: "CREDITED",
    message_status: "CREDITED",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("message_amount" in fieldValues) {
      temp.message_amount = fieldValues.message_amount
        ? +fieldValues.message_amount > 0
          ? ""
          : "Please Enter Valid Payment Amount!"
        : "Please Enter Payment Amount!";
    }
    if ("message_credit" in fieldValues) {
      temp.message_credit = fieldValues.message_credit
        ? +fieldValues.message_credit > 0
          ? ""
          : "Please Enter Valid Credit Amount!"
        : "Please Enter Credit Amount!";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  console.log("values", values);

  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      setValues({
        ...values,
        msgModal: false,
        message_amount: "",
        message_credit: "",
        messageId: "",
        previousAmount: "",
        previousCredit: "",
        payment_status: "CREDITED",
        message_status: "CREDITED",
      });
      console.log("Error", error);
    } else if (isSuccess) {
      toastifyMsg("success", data?.message);
      setValues({
        ...values,
        msgModal: false,
        message_amount: "",
        message_credit: "",
        messageId: "",
        previousAmount: "",
        previousCredit: "",
        payment_status: "CREDITED",
        message_status: "CREDITED",
      });
    }
  }, [isError, error, isSuccess]);

  useEffect(() => {
    if (updateMsgIsError) {
      toastifyMsg("error", updateMsgError?.data?.message);
      setValues({
        ...values,
        msgModal: false,
        message_amount: "",
        message_credit: "",
        messageId: "",
        previousAmount: "",
        previousCredit: "",
        payment_status: "CREDITED",
        message_status: "CREDITED",
      });
      console.log("Error", updateMsgError);
    } else if (Updated) {
      toastifyMsg("success", updatedData?.message);
      setValues({
        ...values,
        msgModal: false,
        message_amount: "",
        message_credit: "",
        messageId: "",
        previousAmount: "",
        previousCredit: "",
        payment_status: "CREDITED",
        message_status: "CREDITED",
      });
    }
  }, [updateMsgIsError, updateMsgError, Updated]);

  const showModal = () => {
    setValues({
      ...values,
      msgModal: true,
    });
  };

  const showUpdateMsgModal = (message) => {
    setValues({
      ...values,
      msgModal: true,
      message_amount: "",
      message_credit: "",
      messageId: message.id,
      previousAmount: message.message_amount,
      previousCredit: message.message_credit,
    });
  };

  console.log("values", values);

  const hideModal = () => {
    setValues({
      ...values,
      msgModal: false,
      message_amount: "",
      message_credit: "",
      messageId: "",
      previousAmount: "",
      previousCredit: "",
      payment_status: "CREDITED",
      message_status: "CREDITED",
    });
    setErrors({
      ...errors,
      message_amount: "",
      message_credit: "",
    });
  };

  const createMsgRecordHandler = () => {
    if (validate()) {
      setValues({
        ...values,
        msgModal: false,
      });
      let data;
      if (!values.messageId) {
        data = {
          message_active: "1",
          message_amount: +values.message_amount,
          message_credit: +values.message_credit,
          salon_owner_id: params.id,
          message_status: "CREDITED",
          salon_id: params.salon_id,
        };
        createMsgRecord(data);
      } else {
        const totalMessageAmount =
          values.payment_status === "CREDITED"
            ? +(+values.previousAmount + +values.message_amount)
            : +(+values.previousAmount - +values.message_amount);
        const totalMessageCredit =
          values.message_status === "CREDITED"
            ? +(+values.previousCredit + +values.message_credit)
            : +(+values.previousCredit - +values.message_credit);
        data = {
          message_active: "1",
          message_amount:
            +totalMessageAmount < 0 ? -totalMessageAmount : totalMessageAmount,

          message_credit:
            +totalMessageCredit < 0 ? -totalMessageCredit : totalMessageCredit,

          payment_status: values.payment_status,
          payment_amount: +values.message_amount,
          updated_message_credit: +values.message_credit,
          message_status: values.message_status,
          salon_owner_id: params.id,
          salon_id: params.salon_id,
        };
        updateMsgRecord({ data: data, id: values.messageId });
      }
      console.log("data", data);
    }
  };

  console.log("values.messageId", values.messageId);
  console.log("values.payment_status", values.payment_status);
  console.log("values.message_status", values.message_status);
  //

  // page change handler
  const onPageChange = () => {
    setPageNumber(page_number);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"MESSAGE MANAGEMENT"} />
        <>
          {messageLoading || Updating || isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="project_edit_main_link">
                <div className="comman_center_btn_container">
                  <button className="go_back_btn" onClick={() => navigate(-1)}>
                    {backBtnArrow} Back
                  </button>
                  <div className="project_extra_btn_head">
                    {/* {!createMsgBtn && ( */}
                    <button
                      className="comman_btn extra_btn_row"
                      onClick={() => showModal()}
                    >
                      Create Message Record
                    </button>
                    {/* )} */}
                  </div>
                </div>
                <Suspense fallback={<Loader />}>
                  <MsgRecordList
                    messageRecord={messageRecordData?.apiResponse}
                    showUpdateMsgModal={showUpdateMsgModal}
                  />
                </Suspense>
              </div>
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            </>
          )}
        </>
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        show={values.msgModal}
        onHide={hideModal}
        centered
      >
        <MsgRecordModal
          hideModal={hideModal}
          values={values}
          errors={errors}
          handleInputChange={handleInputChange}
          createMsgRecordHandler={createMsgRecordHandler}
          messageId={values.messageId}
        />
      </Modal>
    </>
  );
};

export default MessagePage;
