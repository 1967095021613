import { authHeaders } from "../../helpers/axios";
import { apiSlice } from "../api/apiSlice";

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signinUser: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/login",
          method: "post",
          body: data,
        };
      },
    }),
    resetPassFromSuperAdmin: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/auth/password-reset-by-admin/${id}`,
          method: "post",
          body: data,
          headers: authHeaders(),
        };
      },
    }),
    changePasswordFromUser: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/password-reset-by-user",
          method: "post",
          body: data,
          headers: authHeaders(),
        };
      },
    }),
  }),
});

export const {
  useSigninUserMutation,
  useResetPassFromSuperAdminMutation,
  useChangePasswordFromUserMutation,
} = authSlice;
