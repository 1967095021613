import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import {
  CouponFormValidate,
  expiryDateValidation,
  someDaysAfterDate,
  specificSalonValidation,
} from "../../component/Common/PageComponent/CouponComponent/CouponPageComponents";
import CouponPageForm from "../../component/Common/PageComponent/CouponComponent/CouponPageForm";
import { generate_text_code } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { routes } from "../../constants";
import { toastifyMsg } from "../../helpers/commonFunction";
import { useForm } from "../../hooks/useForm";
import { backBtnArrow } from "../../icons";
import { useCreateCouponMutation } from "../../store/Coupon/couponSlice";
import { useGetSalonListQuery } from "../../store/salon/salonSlice";
import { useGetUserListQuery } from "../../store/Users/usersSlice";

const CreateCoupon = ({
  couponId,
  couponDetail,
  updateCoupon,
  isUpdateLoading,
}) => {
  console.log("couponDetail", couponDetail);
  console.log("couponId", couponId);

  const minimumAvaibility =
    couponDetail?.total_availability - couponDetail?.current_availability;

  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  console.log("userId123", userId);

  const [salonId, setSalonId] = useState("");
  console.log("salonId123", salonId);

  const [forAll, setForAll] = useState(
    couponDetail ? (couponDetail.salon_owner_id ? false : true) : true
  );

  // values.total_availability

  // get userList
  const {
    data: userResponse,
    isLoading: userIsLoading,
    isSuccess: userIsSuccess,
  } = useGetUserListQuery({});
  let userList = [];
  if (userIsSuccess) {
    userList = userResponse.apiResponse;
  }

  console.log("userList", userList);

  // get Salon List
  const {
    data: salonResponse,
    isLoading: salonIsLoading,
    isSuccess: salonIsSuccess,
  } = useGetSalonListQuery({ user_id: userId?.value });
  let salonList = [];
  if (salonIsSuccess) {
    salonList = salonResponse.apiResponse;
  }
  console.log("salonList", salonList);

  const [createCoupon, { data, isLoading, error, isError, isSuccess }] =
    useCreateCouponMutation();

  // get Options for select user Name
  const userOptions = userList.map((user, i) => {
    let options = {
      value: user.id,
      label: user.name,
    };
    return options;
  });
  console.log("userOptions", userOptions);

  // get Options for select Salon Name
  const salonOptions = salonList.map((salon, i) => {
    let options = {
      value: salon.id,
      label: salon.salon_name,
    };
    return options;
  });
  console.log("salonOptions", salonOptions);

  // Form Validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return CouponFormValidate(fieldValues, temp, setValues, values, setErrors, {
      forAll,
      minimumAvaibility,
    });
  };
  //

  const initialFValues = {
    coupon_code: "",
    expiry_date: couponDetail
      ? new Date(couponDetail.expiry_date)
      : someDaysAfterDate(15),
    expiry_date_err: "",
    current_availability: "",
    total_availability: couponDetail ? couponDetail.total_availability : "",
    licence_period_in_days: couponDetail
      ? couponDetail.licence_period_in_days
      : "",
    userId_err: "",
    salonId_err: "",
    availabilityDiffrence: couponDetail
      ? +couponDetail.total_availability - couponDetail.current_availability
      : "",
    oldExpiryDate: couponDetail ? couponDetail.expiry_date : "",
    oldLicence_period_in_days: couponDetail
      ? couponDetail.licence_period_in_days
      : "",
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  console.log("values", values);
  console.log("error", errors);

  // salonId validation for specific salon
  const specificSalonValidate = () => {
    return specificSalonValidation(values, setValues, userId, salonId);
  };
  //

  // Expiry Date Validation
  const expiryDateValidate = () => {
    return expiryDateValidation(values, setValues);
  };
  //

  // Create Coupon
  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("IsError", error);
    } else if (isSuccess) {
      navigate(routes.couponMangement);

      toastifyMsg("success", data?.message);
    }
  }, [isError, error, isSuccess]);
  //

  //Individual User Handler
  const userIdHandler = (e) => {
    console.log("e.target", e);
    setUserId(e);
    setValues({
      ...values,
      userId_err: "",
    });
    setSalonId("");
  };
  //

  //Individual Salon Handler
  const salonIdHandler = (e) => {
    console.log("e.target", e);
    setSalonId(e);
    setValues({
      ...values,
      salonId_err: "",
    });
  };
  //

  // change tab handler
  const changeTabHandler = () => {
    setForAll(!forAll);
    setValues({
      total_availability: "",
      licence_period_in_days: "",
      expiry_date: someDaysAfterDate(15),
    });
    setErrors({
      total_availability: "",
      licence_period_in_days: "",
    });
    setUserId("");
  };
  console.log("forAll", forAll);
  //

  const createCouponHandler = (e) => {
    let data;
    if (validate() && expiryDateValidate()) {
      if (forAll) {
        data = {
          coupon_code: generate_text_code(6),
          expiry_date: moment(values.expiry_date).format().split("T")[0],
          current_availability: +values.total_availability,
          total_availability: +values.total_availability,
          licence_period_in_days: +values.licence_period_in_days,
        };
        createCoupon(data);
      } else {
        if (specificSalonValidate()) {
          data = {
            coupon_code: generate_text_code(6),
            salon_owner_id: userId.value,
            salon_id: salonId.value,
            expiry_date: moment(values.expiry_date).format().split("T")[0],
            current_availability: +1,
            total_availability: +1,
            licence_period_in_days: +values.licence_period_in_days,
          };
          createCoupon(data);
        }
      }
      console.log("data", data);
    }
  };

  const updateCouponHandler = () => {
    let data;
    if (validate() && expiryDateValidate()) {
      if (forAll) {
        console.log("UPDATE =====> ALLUSER");

        data = {
          expiry_date: moment(values.expiry_date).format().split("T")[0],
          current_availability:
            +values.total_availability - +values.availabilityDiffrence,
          total_availability: +values.total_availability,
          licence_period_in_days: +values.licence_period_in_days,
        };
      } else {
        console.log("UPDATE =====> SPECIFIC USER");

        data = {
          expiry_date: moment(values.expiry_date).format().split("T")[0],
          current_availability:
            +values.total_availability - +values.availabilityDiffrence,
          total_availability: +values.total_availability,
          licence_period_in_days: +values.licence_period_in_days,
        };
      }
      updateCoupon({ data, id: couponId });

      console.log("dataForUpdate", data);
    }
  };

  console.log("abcde", values);

  console.log(
    "CheckResult",
    couponDetail?.total_availability - couponDetail?.current_availability <=
      values.total_availability
  );

  return (
    <div className="project_edit_main_content">
      <CommonHeading
        heading={couponDetail ? "UPDATE COUPON" : "CREATE COUPON"}
      />
      {userIsLoading || isLoading || isUpdateLoading || salonIsLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <CouponPageForm
            values={values}
            handleInputChange={handleInputChange}
            userOptions={userOptions}
            salonOptions={salonOptions}
            userId={userId}
            userIdHandler={userIdHandler}
            salonId={salonId}
            salonIdHandler={salonIdHandler}
            createCouponHandler={createCouponHandler}
            errors={errors}
            changeTabHandler={changeTabHandler}
            forAll={forAll}
            couponDetail={couponDetail}
            couponId={couponId}
            updateCouponHandler={updateCouponHandler}
            setValues={setValues}
          />
        </>
      )}
    </div>
  );
};

export default CreateCoupon;
