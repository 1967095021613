import React from "react";
import { useNavigate } from "react-router-dom";
import SettingForm from "./SettingForm";
import { useForm } from "../../../../hooks/useForm";
import { backBtnArrow } from "../../../../icons";

const CronUpdate = ({ cronSetting, postCronSetting, updateCronSetting }) => {
  const navigate = useNavigate();

  const initialFValues = {
    send_notes: cronSetting ? cronSetting.send_notes : "",
    send_before: cronSetting ? cronSetting.send_before : 1,
    send_type: cronSetting ? cronSetting.send_type : "BOTH",
    send_time_type: cronSetting ? cronSetting.send_time_type : "DAY",
    active_status: cronSetting ? cronSetting.active_status : "1",
  };

  // // validation
  // const validate = (fieldValues = values) => {
  //   let temp = { ...errors };
  //   return settingFormValidate(fieldValues, temp, setValues, values, setErrors);
  // };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
    // validate
  );
  //

  const createCronSettingHandler = () => {
    const data = {
      // send_notes: values.send_notes,
      send_before: +values.send_before,
      send_type: values.send_type,
      send_time_type: values.send_time_type,
      active_status: 1,
    };
    console.log("dataCRON", data);
    postCronSetting(data);
  };

  const updateCronSettingHandler = () => {
    const data = {
      // send_notes: values.send_notes,
      send_before: +values.send_before,
      send_type: values.send_type,
      send_time_type: values.send_time_type,
      active_status: values.active_status,
    };
    console.log("updateData", data);
    updateCronSetting(data);
  };

  return (
    <>
      <div className="project_edit_main_link">
        <div className="comman_center_btn_container">
          <button className="go_back_btn" onClick={() => navigate(-1)}>
            {backBtnArrow} Back
          </button>
        </div>
      </div>
      <SettingForm
        values={values}
        handleInputChange={handleInputChange}
        setValues={setValues}
        createCronSettingHandler={createCronSettingHandler}
        updateCronSettingHandler={updateCronSettingHandler}
        isUpdate={cronSetting ? true : false}
      />
    </>
  );
};

export default CronUpdate;
