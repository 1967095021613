import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import getRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import "./css/style.css";
// import { checkAuth } from "./helpers/checkAuth";
import { toast } from "react-toastify";
// import { useEffect, useState } from "react";

toast.configure();
function App() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const user = useSelector((state) => state.authStateSlice?.user);
  console.log("user", user);

  // const [err, setErr] = useState(localStorage.getItem("error"));

  // useEffect(() => {
  //   user && checkAuth(dispatch, setErr, navigate);
  // }, [dispatch, err, navigate]);

  const routing = useRoutes(getRoutes(user));

  return <>{routing}</>;
}

export default App;
