export const tableHeadItemsForHistory = [
  {
    id: 1,
    name: "Category",
  },
  {
    id: 2,
    name: "Salon Name",
  },
  {
    id: 3,
    name: "Owner Name",
  },
  {
    id: 4,
    name: "Payment Amount",
  },
  {
    id: 5,
    name: "Payment Date",
  },
  {
    id: 6,
    name: "Payment Status",
  },
  {
    id: 7,
    name: "Message Status",
  },
];
