import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { toastifyMsg } from "../../helpers/commonFunction";
import {
  useGetCronSettingQuery,
  usePostCronSettingMutation,
  useUpdateCronSettingMutation,
} from "../../store/setting/settingSlice";
import CronUpdate from "../../component/Common/PageComponent/SettingsComponent/CronUpdate";

const CronSettingPage = () => {
  const navigate = useNavigate();

  const {
    data,
    isLoading: cronLoading,
    isError: cronIsError,
    error: cronError,
    isSuccess: cronIsSuccess,
  } = useGetCronSettingQuery();

  const [
    postCronSetting,
    { data: postData, isLoading, error, isError, isSuccess },
  ] = usePostCronSettingMutation();

  const [
    updateCronSetting,
    {
      data: updateData,
      isLoading: updateLoading,
      error: updateError,
      isError: isUpdateError,
      isSuccess: updated,
    },
  ] = useUpdateCronSettingMutation();

  // Create Cron Setting
  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("IsError", error);
    } else if (isSuccess) {
      toastifyMsg("success", postData?.message);
      navigate(`/settings`);
    }
  }, [isError, error, isSuccess]);

  // update Cron Setting
  useEffect(() => {
    if (isUpdateError) {
      toastifyMsg("error", updateError?.data?.message);
    } else if (updated) {
      toastifyMsg("success", updateData?.message);
      navigate(`/settings`);
    }
  }, [isUpdateError, updateError, updated]);

  let cronSetting;
  if (cronIsError) {
    toastifyMsg("error", cronError?.data?.message);
  } else if (cronIsSuccess) {
    cronSetting = data;
  }
  console.log("cronSetting", cronSetting);

  //

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"CHANGE SETTING"} />
      {cronLoading || updateLoading || isLoading ? (
        <Loader />
      ) : (
        <>
          <CronUpdate
            cronSetting={cronSetting}
            postCronSetting={postCronSetting}
            updateCronSetting={updateCronSetting}
          />
        </>
      )}
    </div>
  );
};

export default CronSettingPage;
