import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import CommonHeading from "../../component/Common/CommonHeading";
import AddIconModal from "../../component/Common/Modals/AddIconModal";
import {
  useAddServiceIconsMutation,
  useDeleteServiceIconMutation,
  useGetServiceIconsQuery, useListIconCategoryQuery, useUpdateServiceIconMutation,
} from "../../store/serviceIcon/serviceIconSlice";
import { useFilesUploadMutation } from "../../store/fileUploadSlice/filesUploadSlice";
import Loader from "../../component/Common/Loader";
import DeleteIconModal from "../../component/Common/Modals/DeleteIconModal";
import ViewImgIcons from "../../component/Common/PageComponent/IconAndCategoryComponent/ViewImgIcons";
import { toastifyMsg } from "../../helpers/commonFunction";
import UpdateCategoryIconModal from "../../component/Common/Modals/UpdateCategoryIconModal";

const ServiceIcons = () => {
  const { data: serviceIcons, isLoading: serviceIconsLoading } =
    useGetServiceIconsQuery();
  const { data: listCategoryIcons } = useListIconCategoryQuery();
  console.log("serviceIcons", serviceIcons);

  const [addModal, setAddmodal] = useState(false);
  const [imgErrors, setImgErrors] = useState(false);
  const [image, setImage] = useState([]);
  const [imagePrev, setImagePrev] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [imgId, setImgId] = useState("");
  const addServiceIcon = true;

  const showModal = () => {
    setAddmodal(true);
  };

  const hideModal = () => {
    setAddmodal(false);
    setImgErrors("");
    setImage([]);
    setImagePrev([]);
  };

  /////////////////////////////////////////////////////////////

  const [fileUpload, { isLoading: uploading }] = useFilesUploadMutation();

  const [
    addIcons,
    {
      data: fileUploadData,
      isLoading: adding,
      error: addError,
      isError: addIsError,
      isSuccess: addIsSuccess,
    },
  ] = useAddServiceIconsMutation();

  const [
    deleteIcon,
    {
      isLoading: deleting,
      error: deleteError,
      isError: deleteIsError,
      isSuccess: deleteIsSuccess,
    },
  ] = useDeleteServiceIconMutation();

  const [
    updateIcon,
    {
      isLoading: updating,
      error: updateError,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
    }
  ] = useUpdateServiceIconMutation();

  useEffect(() => {
    if (addIsError) {
      toastifyMsg("error", addError?.data?.message);
    } else if (addIsSuccess) {
      setAddmodal(false);
      setImgErrors("");
      setImage([]);
      setImagePrev([]);
      toastifyMsg("success", fileUploadData?.message);
    }
  }, [addIsError, addError, addIsSuccess]);

  useEffect(() => {
    if (deleteIsError) {
      toastifyMsg("error", deleteError?.data?.message);
    } else if (deleteIsSuccess) {
      setDeleteModal(false);
      toastifyMsg("success", "Service icon has been deleted!");
    }
  }, [deleteIsSuccess]);

  useEffect(() => {
    if (updateIsError) {
      toastifyMsg("error", updateError?.data?.message);
    } else if (updateIsSuccess) {
      setEditModal(false);
      toastifyMsg("success", "Service icon has been updated!");
    }
  }, [updateError, updateIsSuccess, updateIsError]);

  const hideDeleteModal = () => {
    setDeleteModal(false);
    setImgId("");
  };

  const showDeleteModal = (id) => {
    setDeleteModal(true);
    setImgId(id);
  };

  console.log("imgId", imgId);

  const deleteImgHandler = () => {
    const data = {
      delete_status: "1",
    };
    deleteIcon({ data, id: imgId });
    setDeleteModal(false);
  };

  const [editModal, setEditModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const showEditModal = (id) => {
    setSelectedIcon(serviceIcons.filter(item => item.id === id)[0]);
    setEditModal(true);
  }

  const hideEditModal = () => {
    setEditModal(false);
  }

  const updateIconCategory = (data) => {
    updateIcon({ data, id: selectedIcon.id});
    setEditModal(false);
  }

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"SERVICE ICONS"} />
        <>
          {serviceIconsLoading || adding || uploading || deleting || updating ? (
            <Loader />
          ) : (
            <ViewImgIcons
              showModal={showModal}
              images={serviceIcons}
              showDeleteModal={showDeleteModal}
              showEditModal={showEditModal}
            />
          )}
        </>
      </div>
      <Modal
        className="modal_main_component modal_background_content modal_add_img_content"
        show={addModal}
        onHide={hideModal}
        centered
      >
        <AddIconModal
          hideModal={hideModal}
          imagePrev={imagePrev}
          imgErrors={imgErrors}
          setImage={setImage}
          image={image}
          setImagePrev={setImagePrev}
          setImgErrors={setImgErrors}
          addServiceIcon={addServiceIcon}
          addIcons={addIcons}
          fileUpload={fileUpload}
          setAddmodal={setAddmodal}
          listIconCategory={listCategoryIcons}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content"
        show={deleteModal}
        onHide={hideDeleteModal}
        centered
      >
        <DeleteIconModal
          hideDeleteModal={hideDeleteModal}
          deleteImgHandler={deleteImgHandler}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content"
        show={editModal}
        onHide={hideEditModal}
        centered
      >
        <UpdateCategoryIconModal
          listIconCategory={listCategoryIcons}
          hideModal={hideEditModal}
          icon={selectedIcon}
          submitHandler={updateIconCategory}
        />
      </Modal>
    </>
  );
};

export default ServiceIcons;
