import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const salonSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSalonList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-salons?user_id=${data.user_id}&start=${
            data.start || 0
          }&limit=${data.limit || 1000000}&search_key=${data.search_key || ""}`,
          method: "GET",
          headers: authHeaders(),
        };
      },

      providesTags: ["Salon"],
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),
    getSalonById: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-salon-by-id?salon_id=${data.salon_id}&user_id=${data.user_id}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["Salon"],
    }),
    createSalon: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/create-salon",
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Salon"],
    }),
    updateSalon: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/auth/update-salon/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Salon"],
    }),
  }),
});

export const {
  useGetSalonListQuery,
  useGetSalonByIdQuery,
  useCreateSalonMutation,
  useUpdateSalonMutation,
} = salonSlice;
