import React from "react";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    id,
    type,
    placeholder,
    className,
    labelClassName,
    inputClassName,
    errorClassName,
    maxLength,
    labelOtherProps,
    labelHeading,
    accept,
    onClick,
    imageProps,
    checked,
    multiple,
    disabled,
    autoFocus,
    eyeIcon,
    eyeIconHandler,
    autoComplete,
    Icon,
    minLength,
    inputLabel,
    inputSpan,
    PasswordClassName,
  } = props;
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={`${labelClassName} ${checked && "checked"}`}
      >
        {labelOtherProps && labelOtherProps}
        {label}
      </label>
      <div
        className={`${
          (eyeIcon || Icon || inputLabel || inputSpan) && "oc_eyeicon_input"
        }`}
      >
        <input
          className={inputClassName}
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onClick={onClick}
          maxLength={maxLength}
          min={minLength}
          accept={accept}
          checked={checked}
          multiple={multiple}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        />
        {eyeIcon && (
          <span
            className={`zu_password_eye_btn ${
              PasswordClassName && "reset_password_eye_btn"
            }`}
            onClick={eyeIconHandler}
          >
            {eyeIcon && eyeIcon}
          </span>
        )}
        {inputLabel && (
          <span className="input_inside_label_text">
            {inputLabel && inputLabel}
          </span>
        )}
        {inputSpan && (
          <span className="input_inside_label_span">
            {inputSpan && inputSpan}
          </span>
        )}
      </div>
      {labelHeading && labelHeading}
      {imageProps && imageProps}
      {error ? <span className={errorClassName}>{error}</span> : null}
    </div>
  );
}
