import React from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { toastifyMsg } from "../../helpers/commonFunction";
import {
  dashboardEmployee,
  dashboardSalon,
  dashboardServices,
  dashboardUser,
} from "../../icons";
import { useGetDashboardDetailsQuery } from "../../store/dashboard/dashboardSlice";

const Dashboard = () => {
  const {
    data: data,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetDashboardDetailsQuery();

  // Get User Detail
  let detail;
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    detail = data?.data;
  }
  //

  console.log("detail", detail);

  const userRole = JSON.parse(localStorage?.getItem("user")).user_role;

  console.log("userRoleDDD", userRole);

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"DASHBOARD"} />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="dashboard_body">
            <div className="dashboard_top_column_row">
              {userRole === "SUPER_ADMIN" && (
                <div className="dashboard_top_column">
                  <div className="dashboard_top_column_main_icon">
                    {dashboardUser}
                  </div>
                  <div className="dashboard_top_column_left_content">
                    <h2 className="dashboard_top_column_left_count_text">
                      {detail?.total_user}
                    </h2>
                    <p className="dashboard_top_column_left_peragraph_text">
                      Users
                    </p>
                  </div>
                </div>
              )}

              <div className="dashboard_top_column">
                <div className="dashboard_top_column_main_icon">
                  {dashboardSalon}
                </div>
                <div className="dashboard_top_column_left_content">
                  <h2 className="dashboard_top_column_left_count_text">
                    {detail?.total_salon}
                  </h2>
                  <p className="dashboard_top_column_left_peragraph_text">
                    Salons
                  </p>
                </div>
              </div>
              {userRole === "ADMIN" && (
                <>
                  <div className="dashboard_top_column">
                    <div className="dashboard_top_column_main_icon">
                      {dashboardEmployee}
                    </div>
                    <div className="dashboard_top_column_left_content">
                      <h2 className="dashboard_top_column_left_count_text">
                        {detail?.total_employee}
                      </h2>
                      <p className="dashboard_top_column_left_peragraph_text">
                        Employees
                      </p>
                    </div>
                  </div>
                  <div className="dashboard_top_column">
                    <div className="dashboard_top_column_main_icon">
                      {dashboardUser}
                    </div>
                    <div className="dashboard_top_column_left_content">
                      <h2 className="dashboard_top_column_left_count_text">
                        {detail?.total_customer}
                      </h2>
                      <p className="dashboard_top_column_left_peragraph_text">
                        Customers
                      </p>
                    </div>
                  </div>
                  <div className="dashboard_top_column">
                    <div className="dashboard_top_column_main_icon">
                      {dashboardServices}
                    </div>
                    <div className="dashboard_top_column_left_content">
                      <h2 className="dashboard_top_column_left_count_text">
                        {detail?.total_service}
                      </h2>
                      <p className="dashboard_top_column_left_peragraph_text">
                        Services
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
