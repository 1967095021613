import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import {
  generate_passCode,
  SalonFormValidate,
  TimeFunction,
  withoutMetadata,
} from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { useForm } from "../../hooks/useForm";
import { useCreateSalonMutation } from "../../store/salon/salonSlice";
import { backBtnArrow } from "../../icons";
import SalonPageForm from "../../component/Common/PageComponent/SalonComponent/SalonPageForm";
import moment from "moment";
import { toastifyMsg } from "../../helpers/commonFunction";

const CreateSalon = ({
  salonDetail,
  salonId,
  updateSalon,
  isUpdateLoading,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  console.log("salonDetail", salonDetail);
  const location = useLocation();

  const userD = location?.state?.userD;
  console.log("userD===>", userD);

  const [createSalon, { data, isLoading, error, isError, isSuccess }] =
    useCreateSalonMutation();

  const initialFValues = {
    salon_name: salonDetail ? salonDetail.salon_name : "",
    salon_owner_name: salonDetail
      ? salonDetail.salon_owner_name
      : userD
      ? userD.name
      : "",
    address: salonDetail ? salonDetail.address : "",
    email: salonDetail ? salonDetail.email : userD ? userD.email : "",
    phone_number: salonDetail
      ? "+" + salonDetail.country_code + " " + salonDetail.phone_number
      : userD
      ? "+" + userD.country_code + " " + userD.phone_number
      : "",
    phone_number_error: "",
    show_booking_till_days: salonDetail
      ? salonDetail.show_booking_till_days
      : "7",
    start_time: salonDetail
      ? salonDetail.start_time
        ? new Date(TimeFunction(salonDetail?.start_time))
        : ""
      : "",

    end_time: salonDetail
      ? salonDetail.end_time
        ? new Date(TimeFunction(salonDetail?.end_time))
        : ""
      : "",
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return SalonFormValidate(fieldValues, temp, setValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  //

  console.log("values.phone_number", values.phone_number);
  // console.log("values.licence_period_in_month", values.licence_period_in_month);

  // Create Salon
  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("IsError", error);
    } else if (isSuccess) {
      navigate(-1);
      toastifyMsg("success", data?.message);
    }
  }, [isError, error, isSuccess]);
  //

  // Country Code and Phone Number Generate Code
  const parsedPhone =
    values.phone_number &&
    withoutMetadata(parsePhoneNumber(values.phone_number));

  const countryCode = parsedPhone?.countryCallingCode;
  const phoneNumber = parsedPhone?.nationalNumber;
  console.log("Phonenumber_and_code", countryCode, "------", phoneNumber);
  //

  console.log("values", values);

  // Add Update Handler
  const createUpdateSalonHandler = (e) => {
    if (validate()) {
      const data = {
        salon_name: values.salon_name,
        salon_owner_name: values.salon_owner_name,
        address: values.address,
        phone_number: phoneNumber,
        country_code: countryCode,
        email: values.email,
        show_booking_till_days: +values.show_booking_till_days,
        start_time: moment(values.start_time).format("HH:mm:ss"),
        end_time: moment(values.end_time).format("HH:mm:ss"),
      };
      if (!salonId) {
        data.salon_owner_id = +params.id;
        data.passcode = generate_passCode();
        data.message_active = "1";
        data.message_status = "CREDITED";
        data.message_amount = 0;
        data.available_message_credit = 500;
        createSalon(data);
      } else {
        updateSalon({ data, id: salonId });
      }

      console.log("dataAddUpdate", data);
    } else {
      console.log(" dataAddUpdate not validate");
    }
  };
  //

  console.log("dateFunction", new Date(TimeFunction(salonDetail?.start_time)));

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={salonId ? "UPDATE SALON" : "ADD SALON"} />
      {isLoading || isUpdateLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <SalonPageForm
            salonId={salonId}
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            createUpdateSalonHandler={createUpdateSalonHandler}
            setValues={setValues}
            setErrors={setErrors}
          />
        </>
      )}
    </div>
  );
};

export default CreateSalon;
