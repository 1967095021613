import { isValidPhoneNumber } from "react-phone-number-input";

// USER TABLE HEAD LIST
export const tableHeadItemsForUser = [
  {
    id: 1,
    name: "User Name",
  },
  {
    id: 2,
    name: "Email",
  },
  {
    id: 3,
    name: "Phone Number",
  },
  {
    id: 4,
    name: "Actions",
  },
];
//

// USER FORM VALIDATIIONS
export const UserFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("name" in fieldValues) {
    temp.name = fieldValues.name.trim() ? "" : "User Name is required!";
  }

  if ("email" in fieldValues) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailReg = new RegExp(mailformat);
    if (!fieldValues.email) {
      temp.email = "Email is required!";
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : "Please enter a valid email!";
    }
  }
  if ("phone_number" in fieldValues) {
    temp.phone_number = fieldValues.phone_number
      ? !isValidPhoneNumber(values.phone_number)
        ? "Phone number is not valid!"
        : ""
      : "Phone number is required!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
//

// USER PAGE FILTERS
export const userPageFilter = ({ onChangingSearchField, searchData }) => {
  return (
    <div className="common_all_filter_row">
      <div className="common_filter_input_box secound_filter_container">
        <input
          type="text"
          placeholder="Search by User/Email..."
          onChange={onChangingSearchField}
          value={searchData}
        />
      </div>
    </div>
  );
};
//

// Reset password validation
export const resetPasswordValidation = (
  temp,
  fieldValues,
  values,
  setErrors,
  admin
) => {
  if (admin) {
    if ("old_password" in fieldValues) {
      temp.old_password = fieldValues.old_password
        ? ""
        : "Old password is required!";
    }
  }
  if ("password" in fieldValues) {
    temp.password = fieldValues.password
      ? fieldValues.password.length < 6
        ? "Password must be at least 6 characters"
        : ""
      : "New password is required!";
  }

  if ("confirm_password" in fieldValues) {
    temp.confirm_password = fieldValues.confirm_password
      ? ""
      : "Confirm the password!";
  }

  setErrors({
    ...temp,
  });

  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
