import React, { useState, useEffect, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import SalonListTable from "../../component/Common/PageComponent/SalonComponent/SalonListTable";
import CommonHeading from "../../component/Common/CommonHeading";
import { useGetSalonListQuery } from "../../store/salon/salonSlice";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { salonPageFilter } from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { useGetUserByIdQuery } from "../../store/Users/usersSlice";
import { backBtnArrow } from "../../icons";
import { clearSalonFilter } from "../../store/filters/filterStateSlice";
import { toastifyMsg } from "../../helpers/commonFunction";
import { Modal } from "react-bootstrap";

const UserDetail = React.lazy(() =>
  import("../../component/Common/PageComponent/UserComponent/UserDetail")
);
const SalonItem = React.lazy(() =>
  import("../../component/Common/PageComponent/SalonComponent/SalonItem")
);
const ResetPasswordModal = React.lazy(() =>
  import("../../component/Common/Modals/ResetPasswordModal")
);

const SalonManagement = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const page_number = params?.page_number;
  const user_id = params?.id;
  console.log("params", params);

  const filters = useSelector((state) => state.filterStateSlice?.items);
  console.log("filters2231", filters);

  const [pageNumber, setPageNumber] = useState(page_number);
  console.log("pageNumber", pageNumber);
  const [searchData, setSearchData] = useState(filters.salonSearch);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const {
    data: response,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetSalonListQuery({
    user_id: user_id,
    start: +pageNumber !== 1 && (pageNumber - 1) * 12,
    limit: 12,
    search_key: searchData,
  });

  const {
    data: user,
    isLoading: userLoading,
    isError: userIsError,
    isSuccess: userIsSuccess,
  } = useGetUserByIdQuery({
    id: user_id,
  });
  console.log("user1234567890", user);

  console.log("response", response);

  const totalCount = response?.totalCount;

  // get salonList List
  let salonList;
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    salonList = response?.apiResponse;
  }
  //

  // Get User Detail
  let userD;
  if (userIsError) {
    toastifyMsg("error", error?.data?.message);
  } else if (userIsSuccess) {
    userD = user;
  }
  //

  // page change handler
  const onPageChange = () => {
    setPageNumber(page_number);
  };
  //

  // filter change handler
  const onChangingSearchField = (e) => {
    setSearchData(e.target.value);
  };

  console.log("searchData", searchData);

  // navigate to 1st page on change filter
  useEffect(() => {
    if (searchData !== "" && pageNumber !== "1") {
      navigate(`/user/${user_id}/salon/page/1`);
    }
  }, [searchData, page_number]);
  //

  const addSalonPageHandler = (userD) => {
    navigate(`/user/${user_id}/create-salon`, {
      state: { userD: userD },
    });
    dispatch(clearSalonFilter());
  };

  const hideModal = () => {
    setChangePasswordModal(false);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="USER DETAILS & SALONS" />
        {isLoading || userLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <button
                  className="go_back_btn table_back_btn"
                  onClick={(e) => {
                    navigate(-1);
                    dispatch(clearSalonFilter());
                  }}
                >
                  {backBtnArrow} Back
                </button>
              </div>
              <div className="common_all_filter_row">
                <button
                  className="reset_password_btn"
                  onClick={() => setChangePasswordModal(true)}
                >
                  Reset Password
                </button>
              </div>
            </div>
            <Suspense fallback={<Loader />}>
              <UserDetail userD={userD} />
            </Suspense>
            <hr />
            {/* <CommonHeading heading="SALON MANAGEMENT" /> */}
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <button
                  className="comman_btn ml-auto"
                  onClick={(e) => addSalonPageHandler(userD)}
                >
                  Add Salon
                </button>
              </div>
              {salonPageFilter({ onChangingSearchField, searchData })}
            </div>

            {/* <div className="custom_data_table_content">
              <SalonListTable
                user_id={user_id}
                list={salonList}
                superAdmin={true}
                searchData={searchData}
              />
            </div> */}
            <Suspense fallback={<Loader />}>
              <SalonItem
                user_id={user_id}
                salonList={salonList}
                superAdmin={true}
                searchData={searchData}
              />
            </Suspense>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
                pageLimit={12}
              />
            )}
          </>
        )}
      </div>
      {changePasswordModal && (
        <Modal
          className="modal_main_component modal_background_content reset_password_modal"
          show={changePasswordModal}
          onHide={hideModal}
          centered
        >
          <Suspense fallback={<Loader />}>
            <ResetPasswordModal
              hideModal={hideModal}
              setChangePasswordModal={setChangePasswordModal}
              user_id={user_id}
            />
          </Suspense>
        </Modal>
      )}
    </>
  );
};

export default SalonManagement;
