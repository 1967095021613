import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastifyMsg } from "../../helpers/commonFunction";

import { useUpdateSalonMutation } from "../../store/salon/salonSlice";
import CreateSalon from "./CreateSalon";

const EditSalon = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  console.log("location", location);

  const salonId = params?.salon_id;
  const salonDetail = location?.state?.salon;

  console.log("salonDetail", salonDetail);

  const [updateSalon, { data, isLoading, error, isError, isSuccess }] =
    useUpdateSalonMutation();

  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("Error", error);
    } else if (isSuccess) {
      navigate(-1);
      toastifyMsg("success", data?.message);
    }
  }, [isError, error, isSuccess]);

  console.log("data", data);

  return (
    <>
      <CreateSalon
        salonDetail={salonDetail}
        salonId={salonId}
        updateSalon={updateSalon}
        isUpdateLoading={isLoading}
      />
    </>
  );
};

export default EditSalon;
