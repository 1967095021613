import { createSlice } from "@reduxjs/toolkit";

const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
console.log("initialUser", initialUser);

const authStateSlice = createSlice({
  name: "auth",
  initialState: {
    user: initialUser,
  },

  reducers: {
    setUser: (state, userdata) => {
      console.log("AuthUserData", userdata);
      state.user = userdata.payload;
    },

    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },
});

export const { setUser, logout } = authStateSlice.actions;
export default authStateSlice.reducer;
