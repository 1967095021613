import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const filesUploadSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    filesUpload: builder.mutation({
      query: (data) => {
        return {
          url: `/smart-beauty/file/upload`,
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
    }),
  }),
});

export const { useFilesUploadMutation } = filesUploadSlice;
