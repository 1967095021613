import React from "react";
import { eyeIcon, hideEyeIcon } from "../../../../icons";
import Button from "../../Button";
import Input from "../../Input";

const LoginPageForm = ({
  values,
  errors,
  setValues,
  handleInputChange,
  signInHandler,
  emailErr,
  isLoading,
}) => {
  const eyeIconHandler = () => {
    setValues({
      ...values,
      inputType: values.inputType === "password" ? "text" : "password",
    });
  };

  return (
    <>
      <Input
        className="login_input_row null"
        errorClassName="err_text"
        type="email"
        placeholder="Email"
        label="Email"
        id="email"
        name="email"
        value={values.email}
        onChange={handleInputChange}
        error={errors?.email || emailErr || ""}
      />
      <Input
        className="login_input_row null"
        errorClassName="err_text"
        type={values.inputType}
        placeholder="Password"
        label="Password"
        id="password"
        name="password"
        value={values.password}
        onChange={handleInputChange}
        error={errors?.password || ""}
        eyeIcon={values.inputType === "password" ? eyeIcon : hideEyeIcon}
        eyeIconHandler={eyeIconHandler}
        autoComplete="on"
      />
      <Button
        type="submit"
        buttonClassName="login_btn"
        onClick={signInHandler}
        text={isLoading ? "Loading..." : "Sign In"}
        disabled={isLoading}
      />
      <span className="login_btn_span">{/* Login with Email */}</span>
    </>
  );
};

export default LoginPageForm;
