import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Modal } from "react-bootstrap";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import BookingTable from "../../component/Common/PageComponent/BookingComponent/BookingTable";
import { backBtnArrow, reFresh } from "../../icons";
import {
  useGetBookingListQuery,
  useGetEmployeeListQuery,
  useGetServiceListQuery,
} from "../../store/bookings/bookingSlice";
import { DateString, toastifyMsg } from "../../helpers/commonFunction";
import { getTwoMonthsBeforeDate } from "../../component/Common/PageComponent/BookingComponent/BookingPageComponents";
import BookingDetailModal from "../../component/Common/Modals/BookingDetailModal";
import Pagination from "../../component/Pagination/Pagination";

const BookingForAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { page_number } = params;
  const user_id = location?.state?.user_id;
  const salon_id = location?.state?.salon_id;
  console.log("page_number", page_number);

  const [pageNumber, setPageNumber] = useState(page_number);

  const beforeTwoMonthDate = getTwoMonthsBeforeDate();
  console.log("beforeTwoMonthDate", beforeTwoMonthDate);
  const [startDate, setStartDate] = useState(beforeTwoMonthDate);
  const [endDate, setEndDate] = useState(new Date());

  const [booking_start_date, setBooking_start_date] = useState(
    DateString(startDate)
  );
  const [booking_end_date, setBooking_end_date] = useState(DateString(endDate));

  const [filterEmployeeId, setFilterEmployeeId] = useState("");
  const [filterServiceId, setFilterServiceId] = useState("");

  const [bookingDetailModal, setBookingDetailModal] = useState(false);
  const [bookingModalData, setBookingModalData] = useState("");
  console.log("bookingModalData", bookingModalData);

  // GET BOOKING LIST
  const {
    data: response,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetBookingListQuery({
    start: +pageNumber !== 1 && (pageNumber - 1) * 10,
    limit: 10,
    user_id: user_id,
    salon_id: salon_id,
    employee_id: filterEmployeeId,
    service_id: filterServiceId,
    booking_start_date: booking_start_date,
    booking_end_date: booking_end_date,
    web: "1",
  });
  console.log("response", response);
  ///////////////

  // get totalCount
  const totalCount = response?.totalCount;
  //

  // get Employee List
  const { data: employees } = useGetEmployeeListQuery({
    user_id: user_id,
    salon_id: salon_id,
  });
  console.log("employees", employees);
  //

  // get Service List
  const { data: services } = useGetServiceListQuery({
    user_id: user_id,
    salon_id: salon_id,
  });
  console.log("services", services);
  //

  //// BOOKING LIST
  let BookingList;
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    BookingList = response?.apiResponse;
  }
  //

  // On DateChange //
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  console.log("startDate", startDate, "=====>", endDate);
  //

  // set booking_date without getting null for API
  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setBooking_start_date(DateString(startDate));
      setBooking_end_date(DateString(endDate));
    }
  }, [startDate, endDate]);

  console.log(
    "booking_start_date",
    booking_start_date,
    "===>",
    booking_end_date
  );
  //

  // reset Calendar Handler //
  const resetDateHandler = () => {
    setStartDate(beforeTwoMonthDate);
    setEndDate(new Date());
  };
  //

  const handleEmployeeIdChange = (e) => {
    setFilterEmployeeId(e.target.value);
  };
  console.log("filterEmployeeId", filterEmployeeId);

  const handleServiceIdChange = (e) => {
    setFilterServiceId(e.target.value);
  };
  console.log("filterServiceId", filterServiceId);

  const hideModal = () => {
    setBookingDetailModal(false);
  };

  const onPageChange = () => {
    setPageNumber(page_number);
  };

  useEffect(() => {
    if (+page_number !== 1) {
      navigate(`salon/salon-bookings/page/1`);
    }
  }, [booking_start_date, filterEmployeeId, filterServiceId]);

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="BOOKING HISTORY" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <button
                  className="go_back_btn table_back_btn"
                  onClick={() => navigate(-1)}
                >
                  {backBtnArrow} Back
                </button>
              </div>
              <div className="common_all_filter_row">
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      onChange={handleServiceIdChange}
                      id="employee_name"
                      name="employee_name"
                      className="select_form"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Filter By Service
                      </option>
                      <option value="">ALL</option>
                      {services?.map((service, i) => {
                        return (
                          <option key={i} value={service.id}>
                            {service.service_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box first_filter_container">
                  <div className="common_select_box">
                    <Form.Select
                      onChange={handleEmployeeIdChange}
                      id="employee_name"
                      name="employee_name"
                      className="select_form"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Filter By Employee
                      </option>
                      <option value="">ALL</option>
                      {employees?.map((employee, i) => {
                        return (
                          <option key={i} value={employee.id}>
                            {employee.employee_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="common_filter_input_box secound_filter_container">
                  <div className="project_date_range_input_container">
                    <div className="custom_date_picker">
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Search By Date Range..."
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                    <span
                      className="filter_input_inside_btn"
                      role="button"
                      onClick={resetDateHandler}
                    >
                      {reFresh}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              <BookingTable
                list={BookingList}
                setBookingModalData={setBookingModalData}
                setBookingDetailModal={setBookingDetailModal}
              />
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        show={bookingDetailModal}
        onHide={hideModal}
        centered
      >
        <BookingDetailModal hideModal={hideModal} bookingD={bookingModalData} />
      </Modal>
    </>
  );
};

export default BookingForAdmin;
