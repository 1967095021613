import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const settingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCronSetting: builder.query({
      query: () => {
        return {
          url: `/smart-beauty/cron/setting`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      keepUnusedDataFor: 5,
      providesTags: ["Setting"],
    }),
    postCronSetting: builder.mutation({
      query: (data) => {
        return {
          url: `/smart-beauty/cron/setting`,
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Setting"],
    }),
    updateCronSetting: builder.mutation({
      query: (data) => {
        return {
          url: `/smart-beauty/cron/setting`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Setting"],
    }),
  }),
});

export const {
  useGetCronSettingQuery,
  usePostCronSettingMutation,
  useUpdateCronSettingMutation,
} = settingSlice;
