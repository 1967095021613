import React, { useState, useEffect, Suspense } from "react";
import { Link, useParams } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { toastifyMsg } from "../../helpers/commonFunction";
import { useGetCouponListQuery } from "../../store/Coupon/couponSlice";

const CouponTable = React.lazy(() =>
  import("../../component/Common/PageComponent/CouponComponent/CouponTable")
);

const CouponManagement = () => {
  const params = useParams();
  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number);

  console.log("page_number", page_number);

  const {
    data: response,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetCouponListQuery({
    start: +pageNumber !== 1 && (pageNumber - 1) * 10,
    limit: 10,
  });

  console.log("response", response);

  const totalCount = response?.totalCount;

  const onPageChange = () => {
    setPageNumber(page_number);
  };

  const [coupons, setCoupons] = useState([]);
  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
    } else if (isSuccess) {
      setCoupons(response?.apiResponse);
    }
  }, [isSuccess, isLoading, response, isError]);

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="COUPON MANAGEMENT" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div>
                <Link to={routes.createCoupon} className="comman_btn ml-auto">
                  Create Coupon
                </Link>
              </div>
              {/* {salonPageFilter({ handleFilterChange, onChangingSearchField })} */}
            </div>

            <div className="custom_data_table_content">
              <Suspense fallback={<Loader />}>
                <CouponTable
                  list={coupons}
                  //   ShowDeleteSalonModal={ShowDeleteSalonModal}
                />
              </Suspense>
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CouponManagement;
