import React from "react";
import Input from "../Input";

const MsgRecordModal = ({
  hideModal,
  values,
  handleInputChange,
  errors,
  createMsgRecordHandler,
  messageId,
}) => {
  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h2>
            {messageId ? "Update Message Record" : "Create Message Record"}
          </h2>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideModal()}>X</button>
        </div>
      </div>
      <div>
        <div className="modal_input_container">
          <Input
            className={`modal_input_field ${
              messageId && "modal_input_label_input_text"
            }`}
            errorClassName="err_text"
            type="number"
            placeholder="Enter Payment Amount"
            label="Payment Amount"
            id="message_amount"
            name="message_amount"
            value={values.message_amount}
            onChange={handleInputChange}
            error={errors?.message_amount || ""}
            inputLabel={
              messageId &&
              values.previousAmount +
                (values.payment_status === "CREDITED" ? " + " : " - ")
            }
            inputSpan={
              messageId && (
                <>
                  <div
                    className="radio"
                    onChange={handleInputChange}
                    value={values.payment_status}
                  >
                    <input
                      // role="button"
                      label="+"
                      type="radio"
                      id="CREDITED"
                      name="payment_status"
                      value="CREDITED"
                      defaultChecked
                    />
                    <input
                      // role="button"
                      label="-"
                      type="radio"
                      id="DEBITED"
                      name="payment_status"
                      value="DEBITED"
                    />
                  </div>
                </>
              )
            }
          />
        </div>
        <Input
          className={`modal_input_field ${
            messageId && "modal_input_label_input_text"
          }`}
          errorClassName="err_text"
          type="text"
          placeholder="Credit Amount of Message"
          label="Message Credit"
          id="message_credit"
          name="message_credit"
          value={values.message_credit}
          onChange={handleInputChange}
          error={errors?.message_credit || ""}
          onKeyPress={(e) => !/^\d*\.?\d*$/.test(e.key) && e.preventDefault()}
          inputLabel={
            messageId &&
            values.previousCredit +
              (values.message_status === "CREDITED" ? " + " : " - ")
          }
          inputSpan={
            messageId && (
              <>
                <div
                  className="radio"
                  onChange={handleInputChange}
                  value={values.message_status}
                >
                  <input
                    // role="button"
                    label="+"
                    type="radio"
                    id="CREDITED"
                    name="message_status"
                    value="CREDITED"
                    defaultChecked
                  />
                  <input
                    // role="button"
                    label="-"
                    type="radio"
                    id="DEBITED"
                    name="message_status"
                    value="DEBITED"
                  />
                </div>
              </>
            )
          }
        />
      </div>

      <div className="modal_btn_row modal_btn_row_top_margin">
        <button
          onClick={() => hideModal()}
          className="modal_btn modal_cancel_btn msg_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_create_btn msg_btn"
          onClick={createMsgRecordHandler}
        >
          {messageId ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default MsgRecordModal;
