import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import "../../css/LoginPage.css";
import { useForm } from "../../hooks/useForm";
import smartBeautyLogo from "../../images/smartBeautyLogo/onlylogo.png";
import {
  emailValidate,
  validateEmail,
} from "../../component/Common/PageComponent/LoginComponent/LoginPageComponents";
import LoginPageForm from "../../component/Common/PageComponent/LoginComponent/LoginPageForm";
import { useSigninUserMutation } from "../../store/auth/authSlice";
import { routes } from "../../constants";
import { setUser } from "../../store/auth/authStateSlice";
import { notify } from "../../helpers/commonFunction";

// toast.configure();
const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailErr, setEmailErr] = useState("");

  const [signinUser, { data, isLoading, error, isError, isSuccess }] =
    useSigninUserMutation();

  const initialFValues = {
    email: "",
    password: "",
    inputType: "password",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    return validateEmail(temp, fieldValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const signInHandler = (e) => {
    e.preventDefault();
    if (validate() && emailValidate({ values, setEmailErr })) {
      const data = {
        email: values.email,
        password: values.password,
      };
      signinUser(data);
    }
  };

  useEffect(() => {
    if (isLoading) {
      console.log("Loading");
    } else if (isError) {
      notify(
        error?.data?.message
          ? error.data.message
          : error?.data?.error
          ? error?.data?.error
          : "Something Went Wrong!"
      );
      console.log("Error", error?.data?.message);
    } else if (isSuccess) {
      console.log("dataData", data);
      // if (data?.data?.user_role === "SUPER_ADMIN") {
      dispatch(setUser(data.data));
      navigate(routes.dashboard);
      notify("welcome Back!", "success");
      localStorage.setItem("user", JSON.stringify(data.data));
      // } else {
      //   notify("You are not authorized!");
      // }
    }
  }, [isLoading, isError, error, isSuccess]);

  useEffect(() => {
    setEmailErr("");
  }, [values.email]);

  return (
    <>
      <section className="login_section">
        <div className="login_content">
          <div className="login_top_content">
            <h3>Welcome Back !</h3>
            <p>Sign in to Smart Beauty</p>
          </div>
          <div className="login_input_logo_content">
            <div className="login_logo">
              <img src={smartBeautyLogo} alt="company_logo" />
            </div>
            <form className="login_input_content">
              <LoginPageForm
                values={values}
                errors={errors}
                setValues={setValues}
                handleInputChange={handleInputChange}
                signInHandler={signInHandler}
                emailErr={emailErr}
                isLoading={isLoading}
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
