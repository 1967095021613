import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../store/api/apiSlice";
import authStateSlice from "./auth/authStateSlice";
import filterStateSlice from "./filters/filterStateSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    authStateSlice,
    filterStateSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
