import React from "react";

const DeleteIconModal = ({ hideDeleteModal, deleteImgHandler, category }) => {
  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h1>Are You Sure?</h1>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideDeleteModal()}>X</button>
        </div>
        {/* <div className="delete_container">{deleteIcon}</div> */}
      </div>

      <div className="modal_paragraph delete_modal_p">
        <p>You want to delete this {category ? " image!" : " Icon!"}</p>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => hideDeleteModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_delete_btn"
          onClick={() => deleteImgHandler()}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteIconModal;
