import React from "react";
import Button from "../../Button";
import { Form } from "react-bootstrap";
import { minusIcon, plusIcon } from "../../../../icons";

const SettingForm = ({
  values,
  handleInputChange,
  createCronSettingHandler,
  updateCronSettingHandler,
  setValues,
  isUpdate,
}) => {
  console.log("values", values);
  console.log("isUpdate", isUpdate);

  return (
    <>
      <div className="create_from_row setting_form_res">
        <div className="project_edit_feature_input_dual_cointainer">
          <div className="project_edit_feature_input">
            <h4 className="project_text_area_label">
              Send Before {values.send_time_type === "DAY" ? " Days" : " Hours"}
            </h4>
            <div className="project_edit_feature_input_inside_div">
              <Form.Select
                onChange={() =>
                  setValues({
                    ...values,
                    send_time_type:
                      values.send_time_type === "DAY" ? "HOURS" : "DAY",
                  })
                }
                className="project_common_select_input"
                id="show_booking_till_days"
                name="show_booking_till_days"
                defaultValue={values.send_time_type}
              >
                <option value="DAY">Days</option>
                <option value="HOURS">Hours</option>
              </Form.Select>
            </div>
          </div>
          <div className="v-counter">
            <div className="v-counter_container">
              <button
                type="button"
                className="counter_minusbtn"
                onClick={() => {
                  setValues({
                    ...values,
                    send_before: (values.send_before -= 1),
                  });
                }}
                disabled={values.send_before === 1}
              >
                {minusIcon}
              </button>

              <span type="text" size="25" className="counter_value">
                {"before" +
                  " " +
                  values.send_before +
                  " " +
                  (values.send_time_type === "DAY" ? " Days" : " Hours")}
              </span>
              <button
                type="button"
                className="counter_plusbtn"
                onClick={() => {
                  setValues({
                    ...values,
                    send_before: (values.send_before += 1),
                  });
                }}
              >
                {plusIcon}
              </button>
            </div>
          </div>
        </div>
        <div></div>

        <div className="project_edit_feature_input">
          <label>Send Type</label>
          <div className="project_common_form_input_check_box_row">
            <div className="project_common_form_check_box">
              <input
                type="radio"
                id="both"
                className="d-none"
                name="send_type"
                onChange={handleInputChange}
                value="BOTH"
                checked={values.send_type === "BOTH"}
              />
              <label htmlFor="both">BOTH</label>
            </div>
            <div className="project_common_form_check_box">
              <input
                type="radio"
                id="sms"
                className="d-none"
                name="send_type"
                onChange={handleInputChange}
                value="SMS"
                checked={values.send_type === "SMS"}
              />
              <label htmlFor="sms">SMS</label>
            </div>
            <div className="project_common_form_check_box">
              <input
                type="radio"
                id="email"
                className="d-none"
                name="send_type"
                onChange={handleInputChange}
                value="EMAIL"
                checked={values.send_type === "EMAIL"}
              />
              <label htmlFor="email">EMAIL</label>
            </div>
          </div>
        </div>
        {/* <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">
            Send Notes{" "}
            <span className="project_text_area_label_optional">
              {"(Optional)"}
            </span>
          </h4>

          <Form.Control
            className="project_text_area"
            type="text"
            placeholder="Enter Send Notes"
            label="Send Notes"
            as="textarea"
            rows={3}
            id="send_notes"
            name="send_notes"
            value={values.send_notes}
            onChange={handleInputChange}
          />
        </div> */}
        <div></div>
        <div className="project_edit_feature_input">
          <label>Status</label>
          <div className="project_common_form_input_check_box_row">
            <div
              className={`project_common_form_check_box ${
                values.active_status === "0" && "check_green"
              }`}
            >
              <input
                type="radio"
                id="active"
                className="d-none"
                name="active_status"
                onChange={handleInputChange}
                value="0"
                checked={values.active_status === "0"}
              />
              <label htmlFor="active">Active</label>
            </div>
            <div
              className={`project_common_form_check_box ${
                values.active_status === "1" && "check_red"
              }`}
            >
              <input
                type="radio"
                id="in_active"
                className="d-none"
                name="active_status"
                onChange={handleInputChange}
                value="1"
                checked={values.active_status === "1"}
              />
              <label htmlFor="in_active">In-Active</label>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={
          isUpdate
            ? () => updateCronSettingHandler()
            : () => createCronSettingHandler()
        }
        text={isUpdate ? "Update" : "Create"}
      />
    </>
  );
};

export default SettingForm;
