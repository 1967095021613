import moment from "moment";
import { toast } from "react-toastify";
import { closeIcon } from "../icons";

const toastList = new Set();
const MAX_TOAST = 1;

// One time toast message show //
export function notify(test, message) {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
}

// tost message for all
export const toastifyMsg = (type, message) => {
  if (type === "error") {
    return toast.error(message, {
      autoClose: 1500,
    });
  } else {
    return toast.success(message, {
      autoClose: 1500,
    });
  }
};

// after uploading images preview it

export const imagePreviewProps = (image, src, imageRemoveHandler) => {
  return (
    image && (
      <>
        <img src={src} alt="benefits logo" />
        <button className="image_remove_btn" onClick={imageRemoveHandler}>
          {closeIcon}
        </button>
      </>
    )
  );
};

// For Drag & Drop functionality

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// For date String value
export const DateString = (startDate) => {
  return moment(startDate).format("YYYY/MM/DD").split("T")[0];
};
