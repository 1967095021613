// Email/Password Required validation in login page
export const validateEmail = (temp, fieldValues, values, setErrors) => {
  if ("email" in fieldValues) {
    temp.email = fieldValues.email ? "" : "Email is required!";
  }

  if ("password" in fieldValues) {
    temp.password = fieldValues.password ? "" : "Password is required!";
  }

  setErrors({
    ...temp,
  });

  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// Email Validation
export const emailValidate = ({ values, setEmailErr }) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if (values.email !== "") {
    if (values.email.match(mailReg)) {
      return true;
    } else {
      setEmailErr("Please enter a valid Email!");
    }
  }
};
