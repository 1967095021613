import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastifyMsg } from "../../helpers/commonFunction";
import { useUpdateUserMutation } from "../../store/Users/usersSlice";

import CreateUser from "./CreateUser";

const EditUser = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const userId = params?.id;
  const userDetail = location?.state?.user;

  console.log("userDetail", userDetail);
  console.log("userId", userId);

  const [updateUser, { data, isLoading, error, isError, isSuccess }] =
    useUpdateUserMutation();

  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("Error", error);
    } else if (isSuccess) {
      navigate(-1);
      toastifyMsg("success", data?.message);
    }
  }, [isError, error, isSuccess]);

  console.log("data", data);

  return (
    <>
      <CreateUser
        userDetail={userDetail}
        userId={userId}
        updateUser={updateUser}
        isUpdateLoading={isLoading}
      />
    </>
  );
};

export default EditUser;
