import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import { goBack, goNext } from "../../icons";

const Pagination = ({ totalRecords, onPageChange, pageLimit }) => {
  const navigate = useNavigate();
  let { page_number } = useParams();
  const pages = pageLimit
    ? Math.ceil(totalRecords / pageLimit)
    : Math.ceil(totalRecords / 10);

  console.log("pageLimit", pageLimit);

  useEffect(() => {
    if (onPageChange) {
      if (parseInt(page_number) === 1) {
        onPageChange(0);
      } else {
        if (pageLimit) {
          onPageChange((parseInt(page_number) - 1) * pageLimit);
        } else {
          onPageChange((parseInt(page_number) - 1) * 10);
        }
      }
    }
  }, [page_number]);

  const handleClick = (page) => {
    let pageNumber = page.selected + 1;
    navigate(
      `${window.location.pathname.split("/page")[0]}/page/${pageNumber}`
    );
    if (onPageChange) {
      if (pageNumber === 1) {
        onPageChange(0);
      } else {
        if (pageLimit) {
          onPageChange((pageNumber - 1) * pageLimit);
        } else {
          onPageChange((pageNumber - 1) * 10);
        }
      }
    }
  };

  return (
    <>
      {pages === 1 || pages === 0 ? null : (
        <ReactPaginate
          previousLabel={goBack}
          nextLabel={goNext}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          onPageChange={handleClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          forcePage={page_number && parseInt(page_number) - 1}
        />
      )}
    </>
  );
};

export default Pagination;
