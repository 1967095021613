export const authHeaders = () => {
  const authToken =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user"))?.token;
  return {
    Authorization: `bearer ${authToken}`,
  };
};

console.log(
  "localUser",
  localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user"))?.token
);
