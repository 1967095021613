import React from "react";

const CronViewPage = ({ cronSetting }) => {
  return (
    <div className="create_from_row">
      <div className="create_from_content_container">
        <div className="project_view_list_item">Before time for MAIL & SMS</div>
        <p>
          {cronSetting.send_before +
            " " +
            (cronSetting.send_time_type === "HOURS"
              ? "hours"
              : cronSetting.send_before === 1
              ? "Day"
              : "Days")}
        </p>
      </div>
      {/* <div>
        <div className="project_view_list_item">Send Notes</div>
        <p>{cronSetting.send_notes}</p>
      </div> */}
      <div>
        <div className="project_view_list_item">Send Type</div>
        <p>
          {cronSetting.send_type === "EMAIL"
            ? "Only Email"
            : cronSetting.send_type === "SMS"
            ? "Only SMS"
            : "Both (Sms & Email)"}
        </p>
      </div>
      <div>
        <div className="project_view_list_item">Current Status</div>
        <p>
          <span
            className={` active_inactive_status
              ${
                cronSetting.active_status == "1"
                  ? "active_status-off"
                  : "active_status-on"
              }
            `}
          >
            ●
          </span>
          {cronSetting.active_status == "1" ? " Inactive" : " Active"}
        </p>
      </div>
    </div>
  );
};

export default CronViewPage;
