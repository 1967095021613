import * as React from "react";

import loaderGif from "../../images/salonLoader.gif";
const Loader = () => {
  return (
    <div className="spinner">
      <img src={loaderGif} alt="loader" />
    </div>
  );
};

export default Loader;
