import React, {useState} from "react";

import Input from "../Input";

const AddIconModal = ({
  hideModal,
  imagePrev,
  // addIconsHandler,
  imgErrors,
  addServiceIcon,
  setImage,
  image,
  setImagePrev,
  setImgErrors,
  addIcons,
  addCategoryIcon,
  fileUpload,
  setAddmodal,
  listIconCategory
}) => {
  const [iconCategory, setIconCategory] = useState();
  const [iconCategoryError, setIconCategoryError] = useState(false);

  ///////////// MULTI IMAGE CHANGE HANDLER ///////////////////
  const multipleHandleInputChange = (e) => {
    let imgErr = "";
    console.log("e.target.files", e.target.files);
    const { files } = e.target;
    console.log("files", files);

    if (files.length > 0) {
      let teImage = [];
      for (let key in files) {
        console.log("key", key);
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            imgErr = "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            imgErr = "Image size should not be greater than 50MB";
            continue;
          }
          teImage.push(files[key]);
        }
      }

      console.log("imgErr", imgErr);
      console.log("teImage", teImage);

      const tempPrev = [...imagePrev];
      for (let i = 0; i < teImage.length; i++) {
        tempPrev.push(URL.createObjectURL(teImage[i]));
      }

      setImage([...image, ...teImage]);
      setImagePrev([...tempPrev]);
      setImgErrors(imgErr);
      console.log("tempPrev", tempPrev);
    }
  };
  ///////////////////////////////////////

  //// Icon Remove Handler ////////////////
  const IconsRemoveHandler = (i) => {
    const imgArr = [...image];
    const imgArrPreview = [...imagePrev];
    imgArr.splice(i, 1);
    imgArrPreview.splice(i, 1);

    setImage(imgArr);
    setImagePrev(imgArrPreview);
  };
  //////////////////////////////////////

  ///// ADD Image Handler //////
  const addIconsHandler = async () => {
    if (addServiceIcon && !iconCategory) {
      setIconCategoryError(true);
      return;
    }
    setAddmodal(false);

    const formData = new FormData();

    let tempImage = [];

    for (let i = 0; i < image.length; i++) {
      if (typeof image[i] !== "string") {
        formData.append("file", image[i]);
        let temp = await fileUpload(formData);
        console.log("temp", temp);
        if (temp) {
          tempImage.push(temp.data.key);
        }
        formData.delete("file");
      } else {
        tempImage.push(image[i]);
      }
    }
    console.log("tempImage", tempImage);
    if (addServiceIcon) {
      const data = {
        icon_image: tempImage,
        category: iconCategory
      };
      addIcons(data);
      console.log("Iconsdata", data);
    } else {
      const data = {
        category_image: tempImage,
      };
      addCategoryIcon(data);
      console.log("CategoryIcon", data);
    }
  };
  ////////

  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h1>{addServiceIcon ? "Add Service Icon" : "Add Category Images"}</h1>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideModal()}>X</button>
        </div>
        {/* <div className="delete_container">{deleteIcon}</div> */}
      </div>
      <div className="project_edit_detail_column_content">
        {/* <h5> Add Icons </h5> */}
        <div className="project_edit_detail_divider">
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              multiple
              id={`service_icon`}
              // label=" Add Icons"
              // labelOtherProps={addIcon}
              name="service_icon"
              accept="image/*"
              onChange={multipleHandleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={
                <div className="img_prev_container">
                  {imagePrev.length > 0 &&
                    imagePrev?.map((image, i) => (
                      <div className="img_prev_inside_container">
                        <img
                          src={image}
                          alt="img"
                          width="300"
                          height="300"
                          key={i}
                        />
                        <button
                          className="image_gallery_remove_btn"
                          onClick={() => IconsRemoveHandler(i)}
                        >
                          <span></span>
                        </button>
                      </div>
                    ))}
                </div>
              }
            />
          </div>
        </div>
      </div>
      {imgErrors && <span className="err_text">{imgErrors}</span>}
      {addServiceIcon &&
        (
          <div className="form-group my-2">
            <label className="form-label fw-bold">Category</label>
            <select className="form-control" onChange={event => {
              setIconCategory(event.target.value);
              setIconCategoryError(!event.target.value);
            }}>
              <option value="" selected={!iconCategory}>--Select category--</option>
              {listIconCategory.map((item) => (
                  <option key={item.id} value={item.id} selected={iconCategory === item.id}>{item.name}</option>
              ))}
            </select>
            {iconCategoryError && <div className="text-danger">Please select category for icons</div>}
          </div>
        )
      }
      <div className="modal_btn_row">
        <button
          onClick={() => hideModal()}
          className="modal_btn modal_cancel_btn modal_add_img_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_create_btn modal_add_img_btn"
          onClick={() => addIconsHandler()}
          disabled={imagePrev.length === 0}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddIconModal;
