import moment from "moment";
import React from "react";
import maleIcon from "../../../images/maleIcon.png";
import femaleIcon from "../../../images/femaleIcon.png";
import { formatTime } from "../PageComponent/BookingComponent/BookingPageComponents";

const BookingDetailModal = ({ hideModal, bookingD }) => {
  console.log("bookingD", bookingD);

  // get service Time in hour minute second format
  const serviceDate = bookingD?.service_time?.split(":");
  console.log("serviceDate", serviceDate);
  let serviceTime = "";
  if (serviceDate) {
    if (serviceDate[0] !== "00") {
      serviceTime += serviceDate[0] + " Hours ";
    }
    if (serviceDate[1] !== "00") {
      serviceTime += serviceDate[1] + " Minutes ";
    }
    if (serviceDate[2] !== "00") {
      serviceTime += serviceDate[2] + " Seconds ";
    }
  }
  console.log("serviceDateString", serviceTime);
  //

  return (
    <div className="modal_container booking_detail_modal">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h2>Booking Details</h2>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideModal()}>X</button>
        </div>
      </div>

      <div className="modal_detail_container">
        <div className="modal_detail_div">
          <p className="modal_head_title">Employee Name :</p>
          <p className="modal_head_desc">{bookingD.employee_name}</p>
        </div>
        <div className="modal_detail_div">
          <p className="modal_head_title">Customer Name :</p>
          <p className="modal_head_desc">
            {bookingD?.customer_name ? bookingD.customer_name : "-"}
          </p>
          {bookingD?.customer_gender && (
            <img
              src={bookingD.customer_gender === "MALE" ? maleIcon : femaleIcon}
              alt="customer_gender"
              className="modal_gender_img"
            />
          )}
        </div>
        <div className="modal_detail_div">
          <p className="modal_head_title">Customer Phone Number :</p>
          <p className="modal_head_desc">
            {" "}
            {bookingD.booking_phone_number
              ? bookingD.booking_phone_number
              : "-"}
          </p>
        </div>
        <div className="modal_detail_div">
          <p className="modal_head_title">Customer Email :</p>
          <p className="modal_head_desc">
            {" "}
            {bookingD.customer_email ? bookingD.customer_email : "-"}
          </p>
        </div>
        <div className="modal_detail_div">
          <p className="modal_head_title">Date & Time :</p>
          <p className="modal_head_desc">
            {" "}
            {moment(bookingD.booking_date).utc().format("DD MMM YYYY")}
            <span className="modal_detail_span">
              {formatTime(bookingD.booking_start_time) +
                " to " +
                formatTime(bookingD.booking_end_time)}
            </span>
          </p>
        </div>
        {bookingD.booking_note && (
          <div className="modal_detail_div">
            <p className="modal_head_title">Booking Notes :</p>
            <p className="modal_head_desc"> {bookingD.booking_note}</p>
          </div>
        )}
        {bookingD.service_name && (
          <div className="modal_detail_div">
            <p className="modal_head_title">Service Name :</p>
            <p className="modal_head_desc"> {bookingD.service_name}</p>
          </div>
        )}
        {bookingD.category_name && (
          <div className="modal_detail_div">
            <p className="modal_head_title">Category Name :</p>
            <p className="modal_head_desc"> {bookingD.category_name}</p>
          </div>
        )}
        {serviceTime && (
          <div className="modal_detail_div">
            <p className="modal_head_title">Service Time :</p>
            <p className="modal_head_desc"> {serviceTime}</p>
          </div>
        )}
        {bookingD.service_fee && (
          <div className="modal_detail_div">
            <p className="modal_head_title">Service Fees :</p>
            <p className="modal_head_desc"> {bookingD.service_fee}</p>
          </div>
        )}
      </div>
      <div className="modal_btn_row"></div>
    </div>
  );
};

export default BookingDetailModal;
