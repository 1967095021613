import React, { Suspense } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { toastifyMsg } from "../../helpers/commonFunction";
import { backBtnArrow } from "../../icons";
import { useGetLicenceListByCouponQuery } from "../../store/Coupon/couponSlice";

const CouponDetail = React.lazy(() =>
  import("../../component/Common/PageComponent/CouponComponent/CouponDetail")
);

const CouponLicenceList = React.lazy(() =>
  import(
    "../../component/Common/PageComponent/CouponComponent/CouponLicenceList"
  )
);

const CouponDetailModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const couponD = location?.state?.coupon;
  console.log("couponD", couponD);

  const { data, isLoading, isError, error, isSuccess } =
    useGetLicenceListByCouponQuery(
      { id: params.id },
      { skip: couponD?.salon_owner_id }
    );

  let licencelist = [];
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    licencelist = data;
  }

  console.log("licencelist", licencelist);
  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"VIEW COUPON"} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <Suspense fallback={<Loader />}>
            <CouponDetail couponD={couponD} />
          </Suspense>
          {!couponD?.salon_owner_id && (
            <Suspense fallback={<Loader />}>
              <CouponLicenceList
                licencelist={licencelist}
                currentAvailability={couponD.current_availability}
                totalAvailability={couponD.total_availability}
              />
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};
export default CouponDetailModal;
