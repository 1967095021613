import moment from "moment/moment";
import React from "react";
import { eyeIcon } from "../../../../icons";
import { formatTime, tableHeadItemsForBooking } from "./BookingPageComponents";

const BookingTable = ({ list, setBookingModalData, setBookingDetailModal }) => {
  console.log("list", list);

  const showModalhandler = (bookingD) => {
    setBookingModalData(bookingD);
    setBookingDetailModal(true);
  };

  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForBooking?.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((bookingD, i) => {
              return (
                <tr className="custom_data_table_row" key={bookingD.id}>
                  <td className="custom_data_table_item table_item">
                    {bookingD.employee_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {bookingD.customer_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {bookingD.service_name ? bookingD.service_name : "-"}
                  </td>

                  <td className="custom_data_table_item table_item">
                    {moment(bookingD.booking_date).utc().format("DD MMM YYYY")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {formatTime(bookingD.booking_start_time) +
                      " to " +
                      formatTime(bookingD.booking_end_time)}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <button
                        className="custom_data_table_view_edit_item_btn"
                        aria-label="view"
                        onClick={() => showModalhandler(bookingD)}
                        // to={`/view-salon/${salon.id}`}
                      >
                        {eyeIcon}
                      </button>
                      {/* <Link
                        aria-label="edit"
                        // to={`${routes.salonManagement}/${salon.id}`}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link> */}

                      {/* <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn delete_btn"
                      >
                        {deleteIcon}
                      </button> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default BookingTable;
