import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";

// SALON FORM VALIDATIIONS
export const SalonFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("salon_name" in fieldValues) {
    temp.salon_name = fieldValues.salon_name.trim()
      ? ""
      : "Salon Name is required!";
  }
  if ("salon_owner_name" in fieldValues) {
    temp.salon_owner_name = fieldValues.salon_owner_name.trim()
      ? ""
      : "Salon owner name is required!";
  }
  if ("address" in fieldValues) {
    temp.address =
      fieldValues.address.trim() && fieldValues.address.length < 10
        ? "Detailed Address is required!"
        : !fieldValues.address.trim()
        ? "Address is required!"
        : "";
  }
  if ("email" in fieldValues) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailReg = new RegExp(mailformat);
    if (!fieldValues.email) {
      temp.email = "Email is required!";
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : "Please enter a valid email!";
    }
  }
  if ("phone_number" in fieldValues) {
    temp.phone_number = fieldValues.phone_number
      ? !isValidPhoneNumber(values.phone_number)
        ? "Phone number is not valid!"
        : ""
      : "Phone number is required!";
  }
  if ("start_time" in fieldValues) {
    temp.start_time = fieldValues.start_time
      ? ""
      : "Salon start time is required!";
  }
  if ("end_time" in fieldValues) {
    temp.end_time = fieldValues.end_time ? "" : "Salon close time is required!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
//

// SALON PAGE FILTERS
export const salonPageFilter = ({ onChangingSearchField, searchData }) => {
  return (
    <div className="common_all_filter_row">
      <div className="common_filter_input_box secound_filter_container">
        <input
          type="text"
          placeholder="Search by Salon/Email..."
          onChange={onChangingSearchField}
          value={searchData}
        />
      </div>
    </div>
  );
};
//

// SALON TABLE HEAD LIST
export const tableHeadItemsForSalon = [
  {
    id: 1,
    name: "salon Name",
  },
  {
    id: 2,
    name: "Owner",
  },
  {
    id: 3,
    name: "Address",
  },
  {
    id: 4,
    name: "Phone Number",
  },
  {
    id: 5,
    name: "Email",
  },
  {
    id: 6,
    name: "Actions",
  },
];
//

// get Country Code and PhoneNumber
export const withoutMetadata = (_parsedPhone) => {
  if (!_parsedPhone) return _parsedPhone;
  const { metadata: _metadata, ...parsedPhone } = _parsedPhone;
  return parsedPhone;
};
//

// Auto passCode Generate
export const generate_passCode = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

// Auto licence_code Generate
export const generate_text_code = (length) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
// Auto licence_code Generate
export const generate_random_code = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// new EpiryDate from current Date
export const newExpiryDateFunction = (days) => {
  // get expiry date string
  const currentDate = Date();
  console.log("currentDate", currentDate);
  var dateString = moment(currentDate).format().split("T")[0];
  console.log("dateString", dateString);
  //

  const totalDays = +days;
  console.log("days", days);

  var newExpiryDate = new Date(dateString); // Date before ADD
  newExpiryDate.setDate(newExpiryDate.getDate() + totalDays); // Set now + 30 days as the new date
  console.log("newExpiryDate", newExpiryDate);

  const newExpiryDateString = moment(newExpiryDate).format().split("T")[0];
  console.log("newExpiryDateString", newExpiryDateString);

  return newExpiryDateString;
};

// check date is past or Future
export const checkDateIsPastOrNot = (date) => {
  if (moment().diff(date) >= 0) {
    return true;
    // Past or current date
  } else return false;
  // It is a future date
};

// get New ExpiryDate from Old ExpiryDate
export const newExpiryFromOldExpiry = (lastExpiryDate, licencePeriod) => {
  var dateString = moment(lastExpiryDate).format().split("T")[0];

  console.log("lastExpiryDate", lastExpiryDate, licencePeriod);

  const totalDays = 30 * +licencePeriod;
  console.log("totalDays123", totalDays);

  var newExpiryDate = new Date(dateString); // Date before ADD
  newExpiryDate.setDate(newExpiryDate.getDate() + totalDays); // Set now + 30 days as the new date
  console.log("newExpiryDate123", newExpiryDate);

  const newExpiryDateString = moment(newExpiryDate).format().split("T")[0];
  console.log("newExpiryDateString123", newExpiryDateString);

  return newExpiryDateString;
};

// get Ago OR IN Months/days/year values
export const daysUntil = (dateToCheckAgainst) => {
  return new moment().to(moment(dateToCheckAgainst));
};

export const DaysInWordFunc = (d) => {
  let months = 0,
    days = 0,
    weeks = 0;

  while (d) {
    if (d >= 30) {
      months++;
      d -= 30;
    } else if (d >= 7) {
      weeks++;
      d -= 7;
    } else {
      days++;
      d--;
    }
  }
  const wordOfDays =
    (months !== 0 ? months + " Months " : "") +
    (weeks !== 0 ? weeks + " Weeks " : "") +
    (days !== 0 ? days + " Days " : "");
  return wordOfDays;
};

// get time value for edit salon
export const TimeFunction = (time) => {
  return moment(`2016-12-08 ${time}`, "YYYY-MM-DD hs A").format(
    "YYYY-MM-DD HH:ss:mm"
  );
};

//
