import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: { userSearch: "", salonSearch: "" },
};

const filterStateSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilter: (state, filterData) => {
      console.log("filterData", filterData);
      if (filterData.payload.name === "userSearch") {
        state.items["userSearch"] = filterData.payload.userSearch;
      }
      if (filterData.payload.name === "salonSearch") {
        state.items["salonSearch"] = filterData.payload.salonSearch;
      }
    },

    clearFilter: (state) => {
      state.items["userSearch"] = "";
      state.items["salonSearch"] = "";
    },

    clearSalonFilter: (state) => {
      state.items["salonSearch"] = "";
    },
  },
});

export const { setFilter, clearFilter, clearSalonFilter } =
  filterStateSlice.actions;
export default filterStateSlice.reducer;
