import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const dashboardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardDetails: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/dashboard`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      keepUnusedDataFor: 5,
      providesTags: ["Salon", "User"],
    }),
  }),
});

export const { useGetDashboardDetailsQuery } = dashboardSlice;
