import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const licenceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLicenceList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-licence-list?user_id=${
            data.user_id
          }&salon_id=${data.salon_id}&start=${data.start || 0}&limit=${
            data.limit || 10
          }`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["Licence"],
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),

    createLicence: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/create-licence",
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Licence", "History"],
    }),

    updateLicence: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/auth/update-licence/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Licence", "History"],
    }),
  }),
});

export const {
  useGetLicenceListQuery,
  useCreateLicenceMutation,
  useUpdateLicenceMutation,
} = licenceSlice;
