import React, { Suspense } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import { toastifyMsg } from "../../helpers/commonFunction";
import { backBtnArrow } from "../../icons";
import { useGetSalonByIdQuery } from "../../store/salon/salonSlice";

const SalonDetail = React.lazy(() =>
  import("../../component/Common/PageComponent/SalonComponent/SalonDetail")
);

const ViewSalonForAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user_id = location?.state?.user_id;
  const salon_id = location?.state?.salon_id;

  console.log("location", user_id, " ------>", salon_id);

  const {
    data: salon,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetSalonByIdQuery({ salon_id: salon_id, user_id: user_id });

  let salonD;
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    salonD = salon;
  }

  console.log("salonD", salonD);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"SALON DETAILS"} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
              <div className="project_extra_btn_head">
                <Link
                  className="comman_btn extra_btn_row"
                  to={`/user/salon-bookings/page/1`}
                  state={{ user_id: user_id, salon_id: salon_id }}
                >
                  View Bookings
                </Link>
              </div>
            </div>
          </div>
          <Suspense fallback={<Loader />}>
            <SalonDetail salonD={salonD} />
          </Suspense>
        </>
      )}
    </div>
  );
};

export default ViewSalonForAdmin;
