import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const couponSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCouponList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/auth/get-coupons?start=${data.start || 0}&limit=${
            data.limit || 10
          }`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["Coupon"],
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),

    createCoupon: builder.mutation({
      query: (data) => {
        return {
          url: "/smart-beauty/auth/create-coupon-code",
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Coupon"],
    }),

    updateCoupon: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/auth/update-coupon-code/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Coupon"],
    }),

    getLicenceListByCoupon: builder.query({
      query: ({ id }) => {
        return {
          url: `/smart-beauty/auth/get-licence-list-by-coupon-id?coupon_id=${id}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["Coupon"],
    }),
  }),
});

export const {
  useGetCouponListQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useGetLicenceListByCouponQuery,
} = couponSlice;
