import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastifyMsg } from "../../helpers/commonFunction";

import { useUpdateCouponMutation } from "../../store/Coupon/couponSlice";
import CreateCoupon from "./CreateCoupon";

const UpdateCoupon = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const couponId = params?.id;
  console.log("couponId", couponId);
  const couponDetail = location?.state?.coupon;
  console.log("couponDetail", couponDetail);

  const [updateCoupon, { data, isLoading, error, isError, isSuccess }] =
    useUpdateCouponMutation();

  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("Error", error);
    } else if (isSuccess) {
      navigate(-1);

      toastifyMsg("success", data?.message);
    }
  }, [isError, error, isSuccess]);

  console.log("data", data);

  return (
    <>
      <CreateCoupon
        couponId={couponId}
        couponDetail={couponDetail}
        updateCoupon={updateCoupon}
        isUpdateLoading={isLoading}
      />
    </>
  );
};

export default UpdateCoupon;
