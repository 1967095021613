import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import {
  generate_random_code,
  withoutMetadata,
} from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { backBtnArrow } from "../../icons";
import { UserFormValidate } from "../../component/Common/PageComponent/UserComponent/UserPageComponents";
import UserPageForm from "../../component/Common/PageComponent/UserComponent/UserPageForm";
import { useCreateUserMutation } from "../../store/Users/usersSlice";
import { toastifyMsg } from "../../helpers/commonFunction";

const CreateUser = ({ userDetail, userId, updateUser, isUpdateLoading }) => {
  const navigate = useNavigate();

  const [createUser, { data, isLoading, error, isError, isSuccess }] =
    useCreateUserMutation();

  const initialFValues = {
    name: userDetail ? userDetail.name : "",
    email: userDetail ? userDetail.email : "",
    phone_number: userDetail
      ? "+" + userDetail.country_code + " " + userDetail.phone_number
      : "",
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return UserFormValidate(fieldValues, temp, setValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  //

  // Create User
  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      console.log("IsError", error);
    } else if (isSuccess) {
      navigate(routes.userManagementByPage);
      toastifyMsg("success", data?.message);
    }
  }, [isError, error, isSuccess]);
  //

  // Country Code and Phone Number Generate Code
  const parsedPhone =
    values.phone_number &&
    withoutMetadata(parsePhoneNumber(values.phone_number));

  const countryCode = parsedPhone?.countryCallingCode;
  const phoneNumber = parsedPhone?.nationalNumber;
  console.log("Phonenumber_and_code", countryCode, "------", phoneNumber);
  //

  console.log("values", values);

  // Add Update Handler
  const createUpdateUserHandler = (e) => {
    if (validate()) {
      const data = {
        name: values.name,
        email: values.email,
        phone_number: phoneNumber,
        country_code: countryCode,
      };
      if (!userId) {
        data.password = generate_random_code(7);
        createUser(data);
      } else {
        updateUser({ data, id: userId });
      }

      console.log("dataAddUpdate", data);
    } else {
      console.log(" dataAddUpdate not validate");
    }
  };
  //

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"ADD USER"} />
      {isLoading || isUpdateLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <UserPageForm
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            createUpdateUserHandler={createUpdateUserHandler}
            setValues={setValues}
            setErrors={setErrors}
            userId={userId}
          />
        </>
      )}
    </div>
  );
};

export default CreateUser;
