import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const bookingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBookingList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/salon/get-bookings?start=${
            data.start || 0
          }&limit=${
            data.limit || 10
          }&customer_name=&employee_name=&booking_date=&employee_id=${
            data.employee_id || ""
          }&service_id=${data.service_id || ""}&user_id=${
            data.user_id
          }&salon_id=${data.salon_id}&customer_id=&booking_start_date=${
            data.booking_start_date
          }&booking_end_date=${data.booking_end_date}&web=${data.web || ""}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      transformResponse(apiResponse, meta) {
        return {
          apiResponse,
          totalCount: Number(meta.response.headers.get("X-Total-Count")),
        };
      },
    }),
    getEmployeeList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/salon/get-employees?user_id=${data.user_id}&salon_id=${data.salon_id}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
    }),
    getServiceList: builder.query({
      query: (data) => {
        return {
          url: `/smart-beauty/salon/get-services?user_id=${data.user_id}&salon_id=${data.salon_id}`,
          method: "GET",
          headers: authHeaders(),
        };
      },
    }),
  }),
});

export const {
  useGetBookingListQuery,
  useGetEmployeeListQuery,
  useGetServiceListQuery,
} = bookingSlice;
