import moment from "moment";

// Booking TABLE HEAD LIST
export const tableHeadItemsForBooking = [
  {
    id: 1,
    name: "Employee Name",
  },
  {
    id: 2,
    name: "Customer Name",
  },
  {
    id: 3,
    name: "Service",
  },
  {
    id: 4,
    name: "Date",
  },
  {
    id: 5,
    name: "Time",
  },
  {
    id: 6,
    name: "Actions",
  },
];

export const getTwoMonthsBeforeDate = () => {
  const currentDate = Date();
  console.log("currentDate", currentDate);
  var dateString = moment(currentDate).format().split("T")[0];
  console.log("dateString", dateString);

  const totalDays = 30 * 2;
  console.log("totalDays", totalDays);

  var beforeDate = new Date(dateString); // Date before ADD
  beforeDate.setDate(beforeDate.getDate() - totalDays); // Set now - 60 days as the new date
  console.log("beforeDate", beforeDate);

  return beforeDate;
};

export const formatTime = (timeString) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
};
