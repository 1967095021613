import React from "react";
import Button from "../../Button";
import Input from "../../Input";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import DatePicker from "react-datepicker";

const SalonPageForm = ({
  salonId,
  values,
  handleInputChange,
  errors,
  createUpdateSalonHandler,
  setValues,
  setErrors,
}) => {
  return (
    <>
      <div className="create_from_row">
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Salon Name"
          label="Salon Name"
          id="salon_name"
          name="salon_name"
          value={values.salon_name}
          onChange={handleInputChange}
          error={errors?.salon_name || ""}
        />
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="email"
          placeholder="Enter Email Address"
          label="Email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors?.email || ""}
        />

        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Enter Salon's Owner Name"
          label="Salon Owner"
          id="salon_owner_name"
          name="salon_owner_name"
          value={values.salon_owner_name}
          onChange={handleInputChange}
          error={errors?.salon_owner_name || ""}
        />
        <div className="project_edit_feature_input">
          <label>Phone Number</label>
          <PhoneInput
            placeholder="Phone number"
            value={values.phone_number}
            defaultCountry="DE"
            onChange={(e) => {
              setValues({
                ...values,
                phone_number: e,
                phone_number_error: "",
              });
              setErrors({
                ...errors,
                phone_number: "",
              });
            }}
          />
          {errors.phone_number && (
            <span className="err_text">{errors.phone_number}</span>
          )}
        </div>
        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Salon Start Time</h4>
          <div className="custom_date_time_picker">
            <DatePicker
              id="start_time"
              selected={values.start_time}
              onChange={(e) => {
                setValues({
                  ...values,
                  start_time: e,
                });
                setErrors({
                  ...errors,
                  start_time: "",
                });
              }}
              placeholderText="Describe salon start time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors.start_time && (
              <span className="err_text">{errors.start_time}</span>
            )}
          </div>
        </div>
        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Salon Close Time</h4>
          <div className="custom_date_time_picker">
            <DatePicker
              id="end_time"
              selected={values.end_time}
              onChange={(e) => {
                setValues({
                  ...values,
                  end_time: e,
                });
                setErrors({
                  ...errors,
                  end_time: "",
                });
              }}
              placeholderText="Describe salon close time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors.end_time && (
              <span className="err_text">{errors.end_time}</span>
            )}
          </div>
        </div>
        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Salon Address</h4>
          <Form.Control
            className="project_text_area"
            type="text"
            placeholder="Enter Salon's Address"
            label="Salon Address"
            as="textarea"
            rows={4}
            id="address"
            name="address"
            value={values.address}
            onChange={handleInputChange}
          />
          <span className="err_text">{errors?.address || ""}</span>
        </div>
        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Show Booking History For</h4>
          <div className="">
            <Form.Select
              onChange={handleInputChange}
              className="project_common_select_input"
              id="show_booking_till_days"
              name="show_booking_till_days"
              defaultValue={values.show_booking_till_days}
            >
              <option value="1">1 Day</option>
              <option value="3">3 Days</option>
              <option value="7">1 Week</option>
              <option value="14">2 Week</option>
              <option value="30">1 Month</option>
              <option value="60">2 Month</option>
              <option value="90">3 Month</option>
              <option value="120">4 Month</option>
              <option value="150">5 Month</option>
              <option value="180">6 Month</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={createUpdateSalonHandler}
        text={salonId ? "Update" : "Add"}
      />
    </>
  );
};

export default SalonPageForm;
